.app-modal,
.app-modal--bg {
	position: fixed;
	z-index: 1002;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.app-modal {
	display: flex;
	padding: 40px;
	overflow-y: auto;
	overflow-x: hidden;
	// pointer-events: none;
	.app-button {
		width: 100%;
	}
	p.message {
		color: $textGrey;
		margin: 0 0 20px;
		font-size: 15px;
		font-weight: 500;
		line-height: 1.4;
		text-align: center;
	}
	&.enter {
		opacity: 0;
		.app-modal--wrap {
			opacity: 0;
			transform: translateY(5px);
		}
	}
	&.enter-active {
		opacity: 1;
		transition: opacity .2s ease, transform .2s ease;
		.app-modal--wrap {
			opacity: 1;
			transform: translateY(0);
			transition: opacity .2s ease, transform .2s ease;
		}
	}
	&.exit {
		opacity: 1;
		.app-modal--wrap {
			opacity: 1;
			transform: translateY(0);
		}
	}
	&.exit-active {
		opacity: 0;
		transition: opacity .2s ease, transform .2s ease;
		.app-modal--wrap {
			opacity: 0;
			transform: translateY(5px);
			transition: opacity .2s ease, transform .2s ease;
		}
	}
	.loader-absolute {
		z-index: 100;
		background: $backgroundPrimary48o;
		&.loader-loading {
			pointer-events: auto;
		}
	}
}

.app-modal--bg {
	background: $black8o;
	backdrop-filter: blur(25px);
}

.app-modal--content {
	width: 375px - 40px;
	padding: 20px;
	position: relative;
	text-align: left;
	background: $backgroundSecondary;
	.app-button:not(:last-child) {
		margin: 0 0 20px;
	}
}

.app-modal--header {
	margin: 0 0 28px;
	display: flex;
	position: relative;
	min-height: 50px;
	align-items: center;
	justify-content: center;
	svg {
		fill: $primary;
	}
	.app-square-icon {
		position: absolute;
		top: 0;
		left: 0;
	}
}

.app-modal--wrap {
	text-align: center;
	position: relative;
	z-index: 1003;
	margin: auto;
}

.app-modal_enhanced {
	.app-modal--bg {
		background: rgba(#000, .8);
		backdrop-filter: blur(0);
	}
	.app-modal--content {
		border: 1px solid $backgroundSecondary10;
		padding: 24px 28px;
		box-shadow: 0 4px 28px $backgroundPrimary18d;
		background: $backgroundSecondary;
		border-radius: $borderRadius + 4;
	}
	.app-modal--header {
		display: flex;
		min-height: auto;
		align-items: center;
		flex-direction: row-reverse;
		justify-content: space-between;
		h4 {
			font-size: 26px;
			font-weight: 600;
		}
		.app-square-icon {
			width: 34px;
			height: 34px;
			min-width: auto;
			min-height: auto;
			position: static;
			svg {
				fill: #fff;
				width: 14px;
				height: 14px;
			}
		}
	}
	.loader-absolute {
		border-radius: $borderRadius + 4;
		backdrop-filter: blur(10px);
	}
}

.stripe--modal {
	.app-button {
		margin: 20px 0 0;
	}
	.stripe-error {
		color: $danger;
		margin: 12px 0 0;
		font-size: 16px;
		font-weight: 600;
		text-align: center;
	}
	.app-modal--content {
		min-height: 327px;
	}
}

.video-record--modal,
.rating--modal {
	.app-modal--content p {
		color: $textWhite6d;
		margin: 0 0 22px;
		text-align: center;
		font-size: 15px;
		font-weight: 600;
		line-height: 1.4;
	}
	p.error {
		color: $danger;
		font-size: 14px;
		font-weight: 500;
	}
}

body.overflowed {
	overflow: hidden;
}

.modal--eula {
	.app-modal--header {
		margin: 0;
		padding: 20px 20px 28px;
		justify-content: flex-start;
		.app-square-icon {
			position: static;
		}
	}
	.app-modal--content {
		width: 800px;
		padding: 0;
		position: relative;
	}
	.modal-actions {
		padding: 30px;
		background: $backgroundPrimary;
		display: flex;
		.app-button:first-child {
			margin: 0 28px 0 0;
		}
	}
	iframe {
		width: 100%;
		height: calc(100vh - 80px - 98px - 30px - 108px);
		display: block;
	}
	@media screen and (max-width: 880px) {
		.app-modal--wrap,
		.app-modal--content {
			width: 100%;
		}
	}
}

.export--modal .app-modal--content {
	p.message {
		color: $textGrey;
		margin: 20px 0;
		font-size: 14px;
		font-weight: 500;
		text-align: center;
	}
	.app-button[class*='primary'] {
		margin: 0 0 20px;
	}
	.app--flag {
		width: 50%;
		margin: 0 20px 0 0;
		&:last-child {
			margin: 0;
		}
	}
	.app--flag-icon:before {
		padding: 0;
	}
	.flags {
		margin: 0 0 20px;
		display: flex;
		font-size: 0;
	}
}

.roster--modal,
.support--modal,
.campaign--modal {
	p.message {
		color: $textWhite6d;
		margin: 0 0 20px;
		font-size: 15px;
		font-weight: 500;
		text-align: center;
		line-height: 1.5;
	}
}

.campaign--modal--roster {
	.app-modal--content {
		width: 420px;
	}
	.roster--confirm {
		margin: 20px -20px -20px;
		bottom: -40px;
		padding: 20px;
		position: sticky;
		backdrop-filter: blur(5px);
		@include gradient(to bottom, $backgroundSecondary215o, $backgroundSecondary);
	}
	.app-input {
		margin: 0 0 20px;
	}
}

.roster--modal {
	.app-square-icon + svg {
		width: 48px;
		height: 30px;
	}
	.app-input {
		margin: 0 0 20px;
	}
}

.campaign--modal .app-button:not(:last-child) {
	margin: 0 0 20px;
}

@media screen and (max-width: 414px) {
	.app-modal {
		padding: 22px;
		min-width: 320px;
	}
	.app-modal--wrap,
	.app-modal--content {
		width: 100%;
	}
	.app-modal--content {
		margin: 0 auto;
	}
}

.app-over-item .app-over-item__inner.send-push {
	width: 540px;
	border: 1px solid $backgroundSecondary;
	padding: 0;
	background: $backgroundPrimary;
	border-radius: $borderRadius;
}

.app-over-item .app-over-item__inner.create-push {
	width: 540px;
}

.send-push {
	position: relative;
	form {
		font-size: 0;
	}
	.modal--footer {
		width: 100%;
		bottom: -40px;
		padding: 22px;
		position: sticky;
		background: $backgroundTertiary4o;
		border-top: 1px solid $backgroundTertiary6;
		border-radius: 0 0 2px 2px;
		backdrop-filter: blur(10px);
		.app-button {
			width: 100%;
		}
	}
}

.send-push--head {
	padding: 22px;
	background: $backgroundPrimary2;
	border-bottom: 1px solid $backgroundSecondary;
	display: flex;
	align-items: center;
	h1 {
		color: $textWhite6d;
		margin: 0 auto 0 16px;
		font-size: 24px;
		font-weight: 700;
	}
}

.send-push--body {
	padding: 22px;
	.app--flag {
		width: 100%;
		display: block;
		&:not(:last-child) {
			margin: 0 0 18px;
		}
	}
	.app-input:not(:last-child),
	.app-legend:not(:last-child) {
		margin: 0 0 22px;
	}
	.field--wrapper {
		padding: 22px;
	}
	.app-button {
		width: 100%;
	}
	.pages {
		margin: 0 auto;
		justify-content: center;
	}
	.filters {
		margin: 12px 0 22px;
		display: flex;
		.app-select.compact {
			width: 100%;
			margin: 0 12px 0 0;
			.app-select--container {
				width: 100%;
			}
			&:last-child {
				margin: 0;
			}
		}
	}
	.message {
		color: $textGrey12d;
		font-size: 15px;
		text-align: center;
		font-weight: 700;
		line-height: 1.6;
		i {
			color: $primary;
			font-style: normal;
		}
		&:not(:last-child) {
			margin: 0 0 18px;
		}
	}
	.app-option-view--value {
		text-align: left;
	}
}

.app--user-compact {
	border: 1px solid $backgroundTertiary4;
	display: flex;
	padding: 12px;
	background: $backgroundSecondary;
	box-shadow: 0 1px 4px $backgroundSecondary6d;
	align-items: center;
	border-radius: $borderRadius;
	justify-content: space-between;
	&:not(:last-child) {
		margin: 0 0 22px;
	}
	&.is-selectable {
		cursor: pointer;
		transition: border-color .2s ease, background .2s ease;
		&:not(.is-selected):hover {
			border-color: $backgroundTertiary8;
			background-color: $backgroundSecondary2;
		}
	}
	&.is-selected {
		border-color: $primary;
		background-color: $primary05o;
	}
	.avatar-empty {
		background: $backgroundTertiary8;
	}
	.app--flag {
		width: 20px;
		align-self: flex-start;
		flex-shrink: 0;
	}
	.content {
		margin: 0 12px;
		min-width: 0;
		flex-grow: 1;
		flex-shrink: 1;
		h4, p {
			overflow: hidden;
			text-overflow: ellipsis;
		}
		h4 {
			color: $textWhite6d;
			margin: 0 0 2px;
			font-size: 15px;
			font-weight: 700;
		}
		p {
			color: $primary;
			font-size: 14px;
			font-weight: 600;
		}
	}
}

.app-over-item .app-over-item__inner.display-promo-code {
	width: 570px;
	.app-legend:not(:last-child) {
		margin: 0 0 20px;
	}
}

.app-over-item .app-over-item__inner.create-ticket {
	max-width: 390px;
	p.message {
		margin: 0 0 20px;
		font-size: 14;
		text-align: center;
	}
}

.form-modal {
	.app-modal--wrap {
		width: 390px;
	}
	.app-modal--content {
		width: 100%;
	}
	.app-input,
	.app--r-select {
		&:not(:last-child) {
			margin: 0 0 20px;
		}
	}
}

.modal_qa {
	.app-modal--wrap {
		width: 620px;
	}
	.app-modal--content {
		width: 100%;
	}
	.qa__item {
		&:not(:last-child) {
			margin: 0 0 18px;
		}
		h4 {
			font: 600 18px/1.2 $fontFamily;
			color: $textWhite10d;
		}
		p {
			font: 400 14px/1.4 $fontFamily;
			color: $textGrey;
			margin: 4px 0 0;
		}
	}
	.actions {
		margin: 40px 0 0;
	}
}
