.app--logo {
	max-width: 190px;
	max-height: 70px;
	img {
		max-width: 190px;
		max-height: 70px;
		object-fit: contain;
	}
}

.bl-primary {
	.app-aside--menu-item .count {
		color: $textBlack;
	}
	.app--check.checked .app--check-trigger svg,
	.app--request-card.finished .request-icon svg {
		fill: $textBlack;
	}
	.app--flag-check svg {
		fill: $textBlack;
	}
}

.bl-btn-secondary {
	.app-button--secondary {
		span {
			color: $textBlack;
		}
		.loader i {
			border-color: $textBlack;
			border-bottom-color: transparent;
		}
	}
}

.bl-btn-primary {
	.app-button--primary {
		span {
			color: $textBlack;
		}
		.loader i {
			border-color: $textBlack;
			border-bottom-color: transparent;
		}
	}
}


.skin-is-centered-tabs {
	.app--tabs-head {
		align-items: center;
		justify-content: center;
		padding: 22px;
		padding-bottom: 0;
		box-shadow: none;
		border-bottom: none;
	}
	.app--tabs-tab {
		width: auto;
		border: 1px solid $backgroundPrimary10;
		min-width: 22%;
		flex-shrink: 0;
		margin-left: 8px;
		margin-right: 8px;
	}
}

.skin-is-floating-legend-title {
	.app-legend-head:after,
	.app-legend-head:before,
	.app-legend-head h4:after {
		display: none;
	}
	.app-legend-head h4 {
		background: $backgroundPrimary12;
	}
	.app--title {
		border: 0;
		z-index: 20;
		padding: 0 14px;
		position: relative;
		background: $backgroundPrimary12;
		box-shadow: 0 2px 4px $backgroundPrimary2o;
		margin-bottom: calc(-28px/2);
		p {
			margin: 0;
			padding: 0;
			line-height: 28px;
		}
	}
	.app-legend--item:first-child .app-option-view:first-child {
		padding-top: calc(20px + 28px/2);
	}
	.video--legend .app-legend-body {
		padding-top: calc(18px + 28px/2);
	}
	.app--wrapper .app--wrapper-inner {
		padding-top: calc(32px + 28px/2) !important;
	}
}


.skin-is-reduced-borders {

	.app-square-icon,
	.app-legend--inner,
	.app-legend--item,
	.app-aside--menu-item,
	.app--tabs-head,
	.app--nav-user,
	.app--nav-user img,
	.app--tabs-tab,
	.skeleton--item,
	.app--notification,
	.search .search-addons .app-select--element,
	.app--athlete-card {
		border: 0;
	}

	.search .search-addons .app-select--element {
		padding: 4.5px 8.5px;
		padding-right: 16.5px;
	}

	.app--athlete-header .__bg-content .img-container,
	.app--athlete-header .__bg-content img {
		border-radius: 0;
	}

	.app-legend--item:not(:first-child) {
		border-top: 1px solid $backgroundPrimary !important;
	}

	.app--request-card {
		border: 0;
	}

	/* Inputs */
	.app--r-select [class$=control],
	.app--input-phone-element,
	.app--input-button-element,
	.app--date-picker-container .form-control,
	.app-input--element {
		border: 0;
	}

}

.skin-is-round {
	.app-square-icon,
	.app--athlete-card .__sport i,
	.app--stats-head img,
	.app--nav-user img {
		border-radius: 50%;
	}
	.app--nav-user,
	.app-aside--menu-item {
		border-radius: 40px;
	}
	.app-legend--inner,
	&.skin-is-centered-tabs .app--tabs-tab {
		border-radius: 10px;
	}
	.app--notification {
		border-radius: 6px;
	}
	.app--athlete-card {
		border-radius: 6px;
	}
	.app-legend-head h4 {
		border-radius: 6px;
	}

	.app--athlete-header .__bg-content .img-container,
	.app--athlete-header .__bg-content img {
		border-radius: 6px;
	}

	&.skin-is-floating-legend-title {
		.app--title {
			border-radius: 6px;
		}
	}

	.app--request-card {
		border-radius: 12px;
	}

	/* Inputs */
	.app--r-select [class$=control],
	.app-input--element,
	.app--date-picker-container .form-control,
	.app--input-button-element,
	.app--input-phone-element {
		border-radius: 6px;
	}

	/* Buttons */
	.app-button,
	.app-button--danger,
	.app-button--primary,
	.app-button--secondary {
		border-radius: 6px;
	}

	.app--wrapper .app--wrapper-inner {
		border-radius: 12px;
	}
}

.skin-is-square {
	.app-square-icon,
	.app-legend--inner,
	.app--athlete-card .__sport i,
	.app--stats-head img,
	.app--nav-user img,
	.app--nav-user,
	.app-aside--menu-item,
	&.skin-is-centered-tabs .app--tabs-tab,
	.app--notification,
	.skeleton--item,
	.app-aside--menu-item .count {
		border-radius: 0;
	}
	.search .search-addons .app-select--element {
		border-radius: 0;
	}
	.app--athlete-card, .app--athlete-card .__photo, .app--athlete-card .__inner {
		border-radius: 0;
	}

	.app--photo-button .icon-style {
		border-radius: 0;
	}

	.app--request-card {
		border-radius: 0;
	}

	/* Inputs */
	.app--r-select [class$=control],
	.app--input-phone-element,
	.app--input-button-element,
	.app--date-picker-container .form-control,
	.app-input--element {
		border-radius: 0;
	}

	/* Buttons */
	.app-button,
	.app-button--danger,
	.app-button--primary,
	.app-button--secondary {
		border-radius: 0;
	}

	.app--wrapper .app--wrapper-inner {
		border-radius: 0;
	}
}

.skin-is-reduced-shadows {
	.app--title,
	.app-button,
	.app-aside--menu-item,
	.app-legend--inner,
	.app-square-icon,
	.app-input--element,
	.app--r-select [class$=control],
	.app--input-button-element,
	.app--date-picker-container .form-control,
	.app--input-phone-element {
		box-shadow: none;
	}
}

.skin-is-prominent {
	.app-main--content {
		background: transparent;
	}
	.app--nav-user {
		background: $backgroundPrimary6;
	}
	.app-aside--menu-item.active p {
		color: $primary;
	}
	.app-legend--inner {
		background: $backgroundPrimary6;
	}
	.app--page-title {
		background: $backgroundPrimary27o;
	}

	.app--request-card {
		background: $backgroundTertiary;
		&:hover {
			background: $backgroundTertiary6;
		}
	}

	/* Inputs */
	.app--r-select [class$=control],
	.app--input-phone-element,
	.app--input-button-element,
	.app--date-picker-container .form-control,
	.app-input--element {
		background: $backgroundPrimary14;
	}

	.app-square-icon {
		background: $backgroundPrimary14;
		&.clickable:not(:disabled):hover {
			background: $backgroundPrimary20;
		}
	}

	.sign-up--title .app-square-icon:not(:disabled) {
		background: transparent;
	}

}

.skin-is-joint-input {

	/* Inputs */
	.app-input,
	.app--r-select,
	.app--input-button,
	.app--date-picker,
	.app--input-phone {
		.app-square-icon {
			margin: 0;
			& + div {
				margin-left: 2px;
				input {
					line-height: 20px;
				}
			}
		}
	}

	.app-input--container {
		&.no-icon {
			margin-left: 50px + 2px;
		}
	}

	.app--r-select [class$=control],
	.app--date-picker-container .form-control,
	.app--input-button-element {
		min-height: 50px;
	}

}

.skin-is-skewed {

	/* Title
	 */
	.app--title,
	.app-legend-head h4 {
		z-index: 20;
		position: relative;
		background: transparent;
		&:before {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			content: '';
			display: block;
			z-index: -1;
			position: absolute;
			transform: skewX(-6deg);
			background: $backgroundPrimary12;
		}
	}

	/* Button
	 */
	.app-button--danger,
	.app-button--primary,
	.app-button--secondary {
		position: relative;
		background: transparent;
		&:after {
			content: '';
			display: block;
			z-index: 20;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transform: skewX(-6deg);
			transition: background-color .2s ease;
			background: $danger2;
		}
		&:not(:disabled):hover {
			background: transparent;
			&:after {
				background: $danger8;
			}
		}
		span {
			z-index: 30;
			position: relative;
		}
	}

	.app-button--primary {
		&:after {
			background: $primary;
		}
		&:not(:disabled):hover:after {
			background: $primary8;
		}
	}

	.app-button--secondary {
		&:after {
			background: $secondary;
		}
		&:not(:disabled):hover:after {
			background: $secondary8;
		}
	}

}

.skin-is-skewed-input {

	/* Input
	 */
	.app-input,
	.app--r-select,
	.app--date-picker,
	.app--input-button,
	.app--input-phone {
		.app-square-icon {
			z-index: 98;
			position: relative;
			margin-right: 0;
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				right: -3px;
				position: absolute;
				z-index: 3;
				width: 5px;
				border: 0 solid transparent;
				pointer-events: none;
				border-top-width: 50px;
				border-top-color: $backgroundPrimary14;
				border-right-width: 5px;
			}
			&:before {
				content: '';
				display: block;
				position: absolute;
				z-index: 4;
				top: 0;
				right: -1px;
				width: 2px;
				height: 100%;
				transform: skewX(-6deg);
				background: $backgroundPrimary6;
			}
		}
		[class$=control],
		.app--input-phone-element,
		.app--input-button-element,
		.app-input--element {
			min-height: 50px;
		}
	}

	.app-input--container.no-icon {
		margin-left: 48px;
	}

	.search {
		& > .loader,
		& > svg {
			left: 17px;
		}
		input {
			padding-left: 50px + 18px;
		}
		.app-input--container {
			&:before {
				content: '';
				width: 2px;
				height: 100%;
				position: absolute;
				pointer-events: none;
				top: 0;
				left: 50px;
				background-color: $backgroundPrimary;
				transform: skewX(-6deg);
			}
		}
	}

}

.skin-is-joint-request-card {

	/* Request cards */
	.app--request-card-inner {
		padding: 0;
		.request-content {
			margin: 0;
			padding: 14px 20px;
			border-left: 2px solid $backgroundPrimary;
		}
		.app-square-icon {
			margin: 20px;
			background: transparent !important;
		}
	}

}

/* Gradient Buttons
 */
.has-button-primary-color {
	.app-button--primary {
		transition: opacity .2s ease;
		@include gradientImp(
			90deg,
			hsl(var(--color-button-primary0-h), var(--color-button-primary0-s), var(--color-button-primary0-l)),
			hsl(var(--color-button-primary1-h), var(--color-button-primary1-s), var(--color-button-primary1-l))
		);
		&:not(:disabled):hover {
			opacity: .8;
			@include gradientImp(
				90deg,
				hsl(var(--color-button-primary0-h), var(--color-button-primary0-s), var(--color-button-primary0-l)),
				hsl(var(--color-button-primary1-h), var(--color-button-primary1-s), var(--color-button-primary1-l))
			);
		}
	}
}

.has-button-secondary-color {
	.app-button--secondary {
		transition: opacity .2s ease;
		@include gradientImp(
			90deg,
			hsl(var(--color-button-secondary0-h), var(--color-button-secondary0-s), var(--color-button-secondary0-l)),
			hsl(var(--color-button-secondary1-h), var(--color-button-secondary1-s), var(--color-button-secondary1-l))
		);
		&:not(:disabled):hover {
			opacity: .8;
			@include gradientImp(
				90deg,
				hsl(var(--color-button-secondary0-h), var(--color-button-secondary0-s), var(--color-button-secondary0-l)),
				hsl(var(--color-button-secondary1-h), var(--color-button-secondary1-s), var(--color-button-secondary1-l))
			);
		}
	}
}
