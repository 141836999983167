.app--photo-button {
	display: flex;
	.trigger {
		width: auto;
		cursor: pointer;
		margin: auto;
		display: inline-flex;
		position: relative;
		overflow: hidden;
		font-size: 0;
		font-family: $fontFamily;
		align-items: center;
		flex-direction: column;
		transform-origin: top center;
	}
	.photo-container {
		position: relative;
	}
	.loader {
		position: absolute;
		top: 1px;
		left: 1px;
		right: 1px;
		bottom: 1px;
		display: flex;
		background: $backgroundTertiary9o;
		border-radius: 2px;
		i {
			margin: auto;
			width: 22px;
			height: 22px;
			border-width: 1.8px;
		}
	}
	.label {
		color: $primary;
		margin: 18px 0 0;
		font-size: 14px;
		font-weight: 700;
	}
	input {
		cursor: pointer;
		position: absolute;
		width: 160%;
		height: 100%;
		top: 0;
		left: -60%;
		right: 0;
		bottom: 0;
		opacity: 0;
	}
	&:hover {
		.app--photo-button-photo {
			border-color: $backgroundPrimary14;
		}
		.app--photo-button-preview {
			background: $backgroundPrimary10;
			border-color: $backgroundPrimary14;
		}
	}
	.icon-style {
		width: 78px;
		height: 78px;
		border: 1px solid $backgroundPrimary12;
		background: $backgroundPrimary8;
		box-shadow: 0 2px 6px $backgroundPrimary26o;
		transition: background-color .2s ease, border-color .2s ease;
		border-radius: $borderRadius;
	}
	&.error {
		.label {
			color: $danger
		}
		.app--photo-button-preview {
			border-color: $danger;
		}
	}
}

.app--photo-button-img {
	display: block;
	object-fit: cover;
}

.app--photo-button-preview {
	padding: 18px;
	svg {
		fill: $primary;
		width: 100%;
		height: 100%;
	}
}
