.app--photo-wrap {
	&:not(:last-child) {
		margin: 0 0 20px;
	}
	img {
		width: 80%;
	}
	&.clickable {
		cursor: pointer;
	}
}
