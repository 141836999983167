.app--pagination {}

.app--pagination--inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.app--pagination-dots {
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.app--pagination-dot {
	width: 8px;
	height: 8px;
	margin: 0 6px;
	transition: background-color .2s ease;
	background: $black4o;
	border-radius: 50%;
	&.selected {
		background: $white8o;
	}
}

.app--pagination-button {
	border: 1px solid $backgroundTertiary6;
	display: flex;
	padding: 10px 16px;
	min-height: 40px;
	background: $backgroundTertiary;
	transition: opacity .1s ease, background-color .1s ease, border-color .1s ease;
	align-items: center;
	border-radius: $borderRadius;
	justify-content: center;
	&:not(:disabled) {
		cursor: pointer;
		&:hover {
			background: $backgroundTertiary4;
			border-color: $backgroundTertiary8;
		}
	}
	&:disabled {
		opacity: .2;
	}
	p {
		color: $primary;
		font-size: 16px;
		font-weight: 600;
	}
	svg {
		fill: $primary;
	}
	&.prev p { margin-left: 10px; }
	&.next p { margin-right: 10px; }
	&.disabled {
		opacity: 0;
		pointer-events: none;
	}
}
