.app--notification {
	margin: 0 0 20px;
	cursor: pointer;
	border: 1px solid $backgroundPrimary10;
	transition: background-color .2s ease, border-color .2s ease;
	background: $backgroundPrimary4;
	box-shadow: 0 2px 4px $backgroundPrimary1d4o;
	border-radius: $borderRadius;
	&:last-child {
		margin: 0;
	}
	&:hover {
		border-color: $backgroundPrimary12;
		background-color: $backgroundPrimary6;
	}
	.read-indictor {
		width: 8px;
		height: 8px;
		position: absolute;
		top: 12px;
		right: 12px;
		background: $primary;
		border-radius: 50%;
	}
	&.is-read {
		background: $backgroundPrimary2;
		border-color: $backgroundPrimary8;
		box-shadow: 0 2px 4px $backgroundPrimary1d1o;
		&:hover {
			background: $backgroundPrimary4;
			border-color: $backgroundPrimary10;
		}
	}
}

.app--notification-inner {
	padding: 12px 18px;
	position: relative;
	h4 {
		color: $textWhite10d;
		font-size: 16px;
		font-weight: 500;
		padding-right: 10px;
	}
	p {
		color: $textGrey;
		margin: 6px 0 0;
		font-size: 12px;
		font-weight: 700;
		text-transform: uppercase;
	}
}
