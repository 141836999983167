.app-option-view {
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	&.vertical {
		align-items: flex-start;
		flex-direction: column;
		.app-option-view--label {
			margin: 0 0 6px;
		}
	}
	&.isPhone {
		.app-option-view--value {
			color: $primary;
			font-weight: 600;
			text-decoration: underline;
		}
	}
	&.isLarge {
		.app-option-view--label {
			font-size: 18px;
		}
		.app-option-view--value {
			font-size: 24px;
		}
	}
}

.app-option-view--label {
	color: $textGrey;
	font-size: 10px;
	font-weight: 700;
	margin-right: 10px;
	text-transform: uppercase;
}

.app-option-view--value {
	color: $textWhite;
	text-align: right;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.4;
}
