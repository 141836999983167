.app--video-wrap {
	width: 80%;
	font-size: 0;
	position: relative;
	.overlay {
		display: flex;
		position: absolute;
		z-index: 20;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: $black8o;
		border-radius: $borderRadius;
		display: flex;
		opacity: 1;
		pointer-events: auto;
		transition: opacity .2s ease;
	}
	&.playing {
		.overlay {
			opacity: 0;
			pointer-events: none;
		}
		video {
			cursor: pointer;
		}
	}
	.watermark {
		position: absolute;
		z-index: 10;
		bottom: 32px;
		right: 32px;
	}
	video {
		width: 100%;
		background: $backgroundSecondary;
		box-shadow: 0 12px 18px $backgroundPrimary18d05o;
		border-radius: $borderRadius;
	}
	button {
		margin: auto;
		cursor: pointer;
		padding: 18px;
		font-size: 0;
		background: $playButtonBackground;
		border-radius: 50%;
		backdrop-filter: blur(10px);
		transition: background-color .2s ease;
		svg {
			fill: $primary;
		}
		&:hover {
			background: $black4o;
		}
	}
	@media screen and (max-width: 560px) {
		width: 100%;
	}
	&.clickable {
		cursor: pointer;
	}
}
