body {
	color: $textWhite;
	min-width: $asideWidth + $mainWidth;
	font-family: $fontFamily;
	background-color: $backgroundPrimary !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

svg {
	fill: $iconWhite;
}

.app-input--element,
.app--input-phone-element {
	background: $inputBackgroundColor;
	box-shadow: $inputBoxShadow;
	font-weight: 600;
	border-radius: $borderRadius;
	@include placeholder(
		$textPlaceholderColor
		$textPlaceholderColorFocus
	);
}

.container {
	height: 100vh;
	padding: 28px 0;
	overflow-y: auto;
	overflow-x: hidden;
}

.container--centered {
	display: flex;
	padding: 0 22px;
	.app--wrapper {
		margin: auto;
	}
	@media screen and (max-width: 320px + 22px * 2) {
		.app--wrapper {
			width: 100%;
		}
	}
}

.wrapper--login {
	h1 {
		margin: 0 0 20px;
	}
	p.header {
		margin: 0 0 12px;
		font-size: 16px;
		text-align: center;
		font-weight: 600;
	}
	.app--separator {
		margin: 0 0 20px;
	}
	.app--wrapper-inner {
		width: 320px;
		border-radius: 5px;
		.app-button--hollow span {
			font-size: 12px;
		}
	}
	.terms-link {
		color: $primary;
		cursor: pointer;
		text-decoration: underline;
	}
	.app--check:not(:last-child),
	.app--input-phone:not(:last-child),
	.app-input:not(:last-child),
	.app-button:not(:last-child) {
		margin: 0 0 20px;
	}
	.app-input:nth-child(2) {
		margin: 0 0 8px;
	}
	.app-button {
		width: 100%;
	}
	.login--socials {
		margin: 0 0 20px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		.app-square-icon {
			margin: 0 10px;
			svg {
				fill: $primary;
			}
		}
	}
	.restore-link {
		margin: 0 0 28px;
		text-align: right;
		a {
			color: $primary;
			font-size: 15px;
			font-weight: 600;
		}
	}
	@media screen and (max-width: 320px + 22px * 2) {
		.app--wrapper-inner {
			width: 100%;
			padding: 22px;
		}
	}
}

.login--restore {
	.app--wrapper-inner {
		width: 340px;
		padding: 20px !important;
	}
	.app--input-code {
		margin: 0 0 20px;
	}
	p.message {
		color: $textGrey;
		margin: 0 0 20px;
		font-size: 15px;
		font-weight: 500;
		line-height: 1.4;
		text-align: center;
	}
	form {
		margin: 0 0 20px;
	}
	@media screen and (max-width: 340px + 22px * 2) {
		.app--wrapper-inner {
			width: 100%;
		}
	}
}

.app-frame {
	display: flex;
	min-height: 100vh;
}

.app-frame--aside {
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	justify-content: flex-end;
	nav {
		margin: 28px 0 0;
	}
}

.app-aside--content {
	width: $asideWidth;
}

.app-nav--wrap {
	width: $asideWidth;
	top: 0;
	bottom: 0;
	padding: 28px 20px 28px 0;
	position: fixed;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.logo {
		display: block;
		font-size: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		button.app-square-icon {
			display: none;
		}
	}
}

.app-nav--user-frame {
	margin: 20px 0 0;
}

.app-aside--menu-item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 0 0 8px;
	padding: 12px 16px;
	border: 1px solid $backgroundPrimary2;
	position: relative;
	background: $backgroundPrimary1d;
	transition: border-color .1s ease, background .1s ease;
	border-radius: $borderRadius;
	&.active {
		background: $backgroundPrimary6;
		border-color: $backgroundPrimary14;
		box-shadow: 0 2px 4px $backgroundPrimary1d;
		p {
			color: $textWhite;
		}
		svg {
			fill: $primary;
		}
	}
	&:last-child {
		margin: 0;
	}
	svg {
		fill: $textGrey;
		flex-shrink: 0;
		// width: 18px;
		max-width: 20px;
		max-height: 20px;
	}
	p {
		flex-grow: 1;
		flex-shrink: 1;
		margin: 0 12px;
		overflow: hidden;
		text-overflow: ellipsis;
		color: $textGrey;
		font-size: 16px;
		font-weight: 600;
	}
	.count {
		padding: 1.5px 4px;
		min-width: 26px;
		min-height: 20px;
		text-align: center;
		background: $primary;
		border-radius: 12px;
		color: $textWhite;
		font-size: 13px;
		font-weight: 700;
		text-align: center;
		opacity: 0;
		transform: scale(.8);
		transition: opacity .2s ease, transform .2s ease;
		&.visible {
			opacity: 1;
			transform: scale(1);
		}
	}
}

.request--view {
	.app-legend:not(:last-child) {
		margin: 0 0 32px;
	}
	.app-legend:not(.multi) .app-legend-body {
		padding: 18px 22px;
	}
	.app-button {
		width: 100%;
		display: block;
		&:not(:last-child) {
			margin: 0 0 22px;
		}
	}
	.app--video-wrap {
		margin: 0 0 22px;
	}
}

.requested--by {
	display: flex;
	align-items: center;
	justify-content: space-between;
	img {
		width: 50px;
		height: 50px;
		border: 1px solid $backgroundPrimary8;
		object-fit: cover;
		background: $backgroundPrimary5;
		box-shadow: 0 4px 6px $backgroundPrimary1d2o;
		border-radius: $borderRadius;
	}
	.content {
		margin: 0 12px;
		flex-grow: 1;
		flex-shrink: 1;
		h5 {
			color: $textWhite6d;
			font-size: 16px;
			font-weight: 700;
		}
		h5 + p {
			margin: 5px 0 0;
			color: $textGrey;
			font-size: 12px;
			font-weight: 700;
			text-transform: uppercase;
		}
	}
}

.app--page-title {
	overflow-anchor: none;
	position: sticky;
	z-index: 100;
	top: 0;
	h1 {
		color: $textWhite10d;
		padding: 16px 22px;
		flex-grow: 1;
		flex-shrink: 1;
		font-size: 18px;
		font-weight: 700;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.app-square-icon.clickable {
		border: 0;
		height: 100%;
		padding: 0 18px 0 22px;
		position: relative;
		font-size: 0;
		min-width: auto;
		min-height: auto;
		background: transparent;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -2;
			opacity: 0;
			pointer-events: none;
			transition: opacity .2s ease;
			@include gradient(
				to right,
				$backgroundPrimary69o,
				$transparent
			);
		}
		svg {
			fill: $iconWhite;
			transition: fill .2s ease, transform .2s ease;
			@include backface(16px);
		}
		&:hover {
			background: transparent !important;
			&:before {
				opacity: 1;
			}
		}
		&:hover svg {
			fill: $iconWhite !important;
			transform: translateX(-2px);
		}
	}
	&.with-back h1 {
		padding: 16px 22px 16px 0;
	}
	.app--page-title__inner {
		padding-right: 22px;
	}
}

.app--page-title__inner {
	width: 100%;
	height: 56px;
	display: flex;
	align-items: center;
	background: $backgroundPrimary49o;
	box-shadow: 0 2px 4px $backgroundPrimary1d4o;
	border-bottom: 1px solid $backgroundPrimary12;
	backdrop-filter: blur(10px);
}

.app--page-content {
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	flex-direction: column;
	.content-search {
		padding: 22px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 0;
		.app-button {
			margin: 0 0 0 22px;
		}
		.search {
			width: 100%;
		}
		.app-input--container {
			width: 100%;
		}
		&.business {
			@media screen and (max-width: 460px) {
				flex-direction: column;
				.app-button {
					width: 100%;
					margin: 18px 0 0;
				}
			}
		}
	}
	.content-legends {
		padding: 22px;
		padding-top: 0;
		padding-bottom: 0;
		.app-tooltip p {
			z-index: 500;
		}
		@media screen and (max-width: 440px) {
			.app-tooltip p {
				top: 28px;
				left: auto;
				right: 0;
			}
		}
	}
	.legends-check {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: flex-start;
		.app-tooltip {
			margin: 6px 0 0 6px;
		}
	}
	.content-featured {
		padding: 20px;
		padding-top: 18px;
		padding-bottom: 0;
		p {
			font-size: 18px;
			font-weight: 600;
		}
	}
	.has-featured {
		padding-top: 10px;
	}
	&.has-message {
		.app--page-content-inner {
			display: flex;
			align-items: flex-start;
			justify-content: center;
			div {
				// margin: auto;
				padding: 22px;
				text-align: center;
				background: $black2o;
				border-radius: $borderRadius;
			}
			h4 {
				margin: 0 0 6px;
				font-size: 22px;
				font-weight: 700;
			}
			p {
				color: $textGrey;
				font-size: 16px;
				font-weight: 600;
			}
		}
	}
	&.empty {
		.app--page-content-inner {
			display: none;
		}
	}
}

.app--page-content-inner {
	padding: 22px;
	display: flex;
	position: relative;
	flex-grow: 1;
	flex-shrink: 1;
	flex-direction: column;
	.app--tabs {
		flex-grow: 1;
		flex-shrink: 1;
	}
}

.app--page-content-header {

}

.app--page-content-empty {
	flex-grow: 1;
	flex-shrink: 1;
	display: flex;
	padding: 22px;
	.content--empty {
		color: $textWhite20d;
		border: 1px solid $backgroundPrimary10;
		margin: auto;
		padding: 22px 26px;
		font-size: 22px;
		box-shadow: 0 2px 6px $backgroundPrimary2o;
		background: $backgroundPrimary4;
		text-align: center;
		font-weight: 600;
		line-height: 1.6;
		white-space: pre-line;
		border-radius: $borderRadius;
	}
}

.app-frame--main {
	display: flex;
	align-items: stretch;
	flex-grow: 1;
	flex-shrink: 1;
}

.app-main--content {
	width: $mainWidth;
	display: flex;
	flex-direction: column;
	flex-shrink: 1;
	position: relative;
	border: 1px solid $backgroundPrimary12;
	border-top: 0;
	border-bottom: 0;
	background: $backgroundPrimary2;
}

button.menu {
	width: 56px;
	height: 56px;
	cursor: pointer;
	display: none;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	i,
	&:before,
	&:after {
		width: 16px;
		height: 2px;
		content: '';
		margin: 0 0 3.5px;
		display: block;
		background: $iconWhite;
		border-radius: 2px;
	}
	&:after {
		margin: 0;
	}
}

.app-aside--bg {
	display: none;
}

@media screen and (max-width: 1180px) {

	body {
		min-width: 320px;
	}

	.app-frame--aside {
		pointer-events: none;
		@include absolute-fill(980);
		position: fixed;
	}

	.app-aside--bg {
		display: block;
		opacity: 0;
		transition: opacity .2s ease;
		background: $black8o;
		pointer-events: none;
		@include absolute-fill(980);
		position: fixed;
	}

	.app-aside--content {
		opacity: 0;
		transform: translateX(10%);
		background: $backgroundPrimary;
		transition: transform .2s ease, opacity .2s ease;
		border-left: 1px solid $backgroundPrimary6;
		@include absolute-fill(981, $left: auto);
		pointer-events: none;
	}

	.app-nav--wrap {
		.logo {
			button.app-square-icon {
				display: flex;
			}
		}
	}

	.menu-shown {
		.app-aside--bg {
			opacity: 1;
			pointer-events: auto;
		}
		.app-aside--content {
			opacity: 1;
			transform: translateX(0);
			pointer-events: auto;
		}
	}

	.app-nav--wrap {
		padding: 28px 20px;
	}

	.app-frame--main {
		width: 100%;
		padding: 0 18px;
	}

	.app-main--content {
		width: 100%;
	}

	.app--page-title {
		padding-right: 0;
		button.menu {
			display: flex;
		}
	}

}

@media screen and (max-width: 860px) {

	.grid-4s > div {
		width: calc(48.5%) !important;
		margin: 0 3% 3% 0 !important;
		&:nth-child(2n) {
			margin-right: 0 !important;
		}
		&:nth-last-child(-n+2) {
			margin-bottom: 0 !important;
		}
	}

}

@media screen and (max-width: 660px) {

	.grid-3s > div {
		width: calc(48.5%) !important;
		margin: 0 3% 3% 0 !important;
		&:nth-child(2n) {
			margin-right: 0 !important;
		}
		&:nth-last-child(-n+2) {
			margin-bottom: 0 !important;
		}
	}

}

@media screen and (max-width: 540px) {

	.app-frame.has-menu {
		padding-bottom: 50px;
	}

	.app-frame--main {
		padding: 0;
	}

	.app-main--content {
		border-left: 0;
		border-right: 0;
	}

	.app--page-title button.menu {
		display: none;
	}

	.app-frame--aside {
		height: 50px;
		pointer-events: none;
		@include absolute-fill(980, $top: auto);
		position: fixed;
		nav {
			margin: 0;
			display: flex;
			padding: 0 20px;
			justify-content: space-between;
		}
	}

	.app-aside--bg {
		display: none;
	}

	.app-aside--content {
		width: 100%;
		opacity: 0;
		transform: translateX(0) translateY(100%) !important;
		background: $backgroundTertiary94o;
		border-top: 1px solid $backgroundTertiary6;
		border-left: none;
		pointer-events: auto;
		backdrop-filter: blur(10px);
		@include absolute-fill(981);
	}

	.has-menu .app-aside--content {
		opacity: 1;
		transform: translateX(0) translateY(0%) !important;
	}

	.app-nav--wrap {
		width: 100%;
		padding: 0;
		.logo {
			display: none;
		}
	}

	.app-aside--menu-item {
		margin: 0;
		border: 0;
		height: 49px;
		display: flex;
		font-size: 0;
		background: transparent;
		border-radius: 0;
		svg {
			fill: $iconWhite;
			width: 22px;
			margin: auto;
		}
		p {
			display: none;
		}
		&.active {
			background: transparent;
			box-shadow: none;
		}
		.count {
			position: absolute;
			top: 6px;
			right: 0;
			padding: 0.5px 3px;
			min-width: 18px;
			font-size: 11px;
			border-radius: 6px;
		}
	}

	.app-nav--user-frame {
		display: none;
	}

}
