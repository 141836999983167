.app--map-wrap {
	position: absolute;
	z-index: 99;
	min-width: 320px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding-bottom: 88px;
	opacity: 0;
	outline: none;
	background: $backgroundPrimary2;
	transition: opacity .2s ease;
	pointer-events: none;
	&.map--shown {
		opacity: 1;
		pointer-events: auto;
	}
	.map--container * {
		outline: none !important;
		border: 0 !important;
	}
	.gmnoprint, .gm-style-cc {
		display: none;
	}
	.app-square-icon {
		margin: 0 15px 0 0;
		svg {
			fill: $iconWhite;
		}
	}
	.map--search {
		position: absolute;
		z-index: 2000;
		top: 0;
		left: 0;
		right: 0;
		padding: 22px;
		display: flex;
		align-items: center;
		justify-content: stretch;
		@include gradient(
			to bottom,
			$backgroundPrimary,
			$transparent
		);
	}
	.search,
	.app-input--container {
		width: 100%;
	}
	.location-frame {
		display: flex;
		align-items: center;
		h4 {
			font-size: 18px;
			font-weight: 500;
			flex-grow: 1;
			flex-shrink: 1;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
		.app--quantity-selector {
			margin: 0 18px;
			flex-shrink: 0;
		}
		.app-button {
			width: auto;
		}
	}
	@media screen and (max-width: 540px) {
		padding-bottom: 124px;
		.location-frame {
			flex-wrap: wrap;
			justify-content: space-between;
			.app--quantity-selector {
				margin: 0 22px 0 0;
			}
			h4 {
				width: 100%;
				margin: 0 0 16px;
				font-size: 16px;
				flex-grow: 1;
				min-height: 20;
				flex-shrink: 0;
				white-space: nowrap;
			}
			.app-button {
				flex-grow: 1;
			}
		}
	}
	@media screen and (max-width: 374px) {
		padding-bottom: 180px;
		.location-frame {
			flex-direction: column;
			align-items: stretch;
			.app--quantity-selector {
				margin: 0 0 12px;
			}
		}
	}
}
