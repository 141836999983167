.app--nav-user {
	padding: 12px;
	display: flex;
	align-items: center;
	border: 1px solid $backgroundPrimary3;
	background: $backgroundPrimary1d;
	border-radius: $borderRadius;
	img,
	.app-square-icon {
		width: 42px;
		height: 42px;
		flex-shrink: 0;
	}
	img {
		border: 1px solid $backgroundPrimary12;
		box-shadow: 0 2px 4px $backgroundPrimary1d4o;
		border-radius: $borderRadius;
		background: $backgroundPrimary2;
	}
	.content {
		margin: 0 12px;
		min-width: 0;
		flex-grow: 1;
		flex-shrink: 1;
	}
	.app-square-icon {
		min-width: auto;
		min-height: auto;
	}
	h4 {
		margin: 0 0 4px;
		color: $textWhite6d;
		font-size: 15px;
		font-weight: 600;
		min-width: 0;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	h4 + p {
		color: $textGrey;
		font-size: 12px;
		font-weight: 700;
	}
}
