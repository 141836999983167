.manage-aside {
	width: 230px;
	position: fixed;
	display: flex;
	flex-direction: column;
	top: 0;
	left: 0;
	right: auto;
	bottom: 0;
	padding: 28px 20px;
	transform: translate3d(0, 0, 0);
	background: $backgroundSecondary8d;
	border-right: 1px solid $backgroundSecondary1d;
	.link-wrap {
		span:first-child:last-child {
			margin-left: calc(14px);
		}
		.link-wrap__items {
			padding: 8px;
			display: none;
			& > a {
				background: $backgroundPrimary6;
				box-shadow: none !important;
				transition: background-color .2s ease, box-shadow .2s ease;
				&:not(:last-child) {
					margin: 0 0 8px;
				}
				&:not(.active):hover {
					background: $backgroundPrimary8;
				}
				&.active {
					background: $backgroundPrimary10 !important;
					box-shadow: 0 2px 6px $backgroundPrimary6 !important;
				}
			}
		}
		&.active {
			margin: 0 0 10px;
			// background: $backgroundSecondary;
			background: $backgroundPrimary2;
			box-shadow: 0 2px 6px $backgroundPrimary;
			border-radius: $borderRadius + 5;
			& > a {
				color: $primary !important;
				background: transparent !important;
				box-shadow: none !important;
				margin-bottom: 0 !important;
				svg {
					fill: $primary !important;
				}
			}
			.link-wrap__items {
				display: block;
			}
		}
	}
	nav {
		margin: 16px -5px 0;
		flex-grow: 1;
		a {
			font: 600 15.5px $fontFamily;
			color: $textGrey;
			display: block;
			padding: 8px 17px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			user-select: none;
			transition: background-color .2s ease;
			border-radius: $borderRadius + 5;
			&:not(.active):hover {
				background: $backgroundPrimary2;
			}
			&:not(:last-child) {
				margin: 0 0 10px;
			}
			span {
				transition: color .2s ease;
			}
			&.active {
				color: $primary;
				background: $backgroundSecondary;
				box-shadow: 0 2px 6px $backgroundSecondary8d;
				svg {
					fill: $primary;
				}
			}
			svg {
				fill: $textGrey;
				width: 14px;
				margin: 0 8px 0 0;
			}
		}
	}
	.logout button {
		width: 100%;
	}
}

.manage-content {
	padding: 38px;
	position: fixed;
	min-width: 800px;
	top: 0;
	left: 230px;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	overflow-x: hidden;
	&.with-side {
		right: 413px;
		min-width: 680px;
	}
	&.frame {
		padding: 0;
		iframe {
			width: 100%;
			height: 100%;
			border: 0;
		}
	}
}

.page-empty {
	font-size: 16px;
	font-weight: 500;
}

.page-aside {
	width: 414px;
	position: fixed;
	top: 0;
	left: auto;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	overflow-x: hidden;
	border-left: 1px solid $backgroundPrimary10;
	background-color: $backgroundPrimary4;
}

.page-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 0 20px;
	h1 {
		display: flex;
		font-size: 28px;
		align-items: center;
		.loader {
			margin: 3px 0 0 9px;
		}
		.user-avatar {
			width: 35px;
			margin: 0 12px 0 0;
			img {
				width: 35px;
				height: 35px;
			}
		}
		span, a {
			margin: 3px 0 0 9px;
			color: $primary;
			font-size: 20px;
			font-weight: 600;
		}
		.app-square-icon {
			width: 35px;
			height: 35px;
			margin: 0 14px 0 0;
			min-width: auto;
			min-height: auto;
		}
	}
	.amount {
		color: $primary;
		padding: 7.5px 9.5px;
		font-size: 16px;
		font-weight: 600;
		background: $primary1o;
	}
}

.payouts--page .page-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 38px;
	padding-top: 22px;
	.pages {
		margin: 0;
	}
}

.page-middle {
	margin: 38px 0 20px;
	display: flex;
	align-items: center;
	.app-select {
		margin: 0 20px 0 0;
		&:last-child {
			margin: 0;
		}
	}
}

.manage-chats {
	.page-middle {
		display: block;
	}
	.filters {
		display: flex;
	}
	.search-chips {
		margin: 28px 0 0;
		display: flex;
		align-items: center;
	}
}

.search-chip {
	display: flex;
	background: $backgroundPrimary10;
	border: 1px solid $primary;
	box-shadow: 0 4px 12px $primary15o;
	border-radius: 20px;
	padding: 4px 4px 4px 12px;
	align-items: center;
	justify-content: center;
	p {
		font: 500 16px $fontFamily;
		color: $textWhite10d;
		&:last-of-type {
			margin: 0 8px 0 0;
		}
	}
	b {
		font: 400 14px $fontFamily;
		color: $textGrey;
		margin: 0 4px 0 0;
	}
	.app-square-icon {
		width: 22px;
		height: 22px;
		min-width: auto;
		min-height: auto;
		border-radius: 50%;
		svg {
			width: 10px;
			height: 10px;
		}
	}
}

.page-controls {
	display: flex;
	.app-button {
		margin: 0 20px 0 0;
		&:last-child {
			margin: 0;
		}
	}
}

.users-list {

}

.users-list--item {
	padding: 12px;
	margin: 0 0 20px;
	border: 1px solid $backgroundPrimary5;
	display: flex;
	align-items: center;
	flex-direction: row;
	background: $backgroundPrimary2;
	transition: background-color .2s ease, border-color .2s ease;
	border-radius: $borderRadius;
	&:hover {
		background: $backgroundPrimary4;
		border-color: $backgroundPrimary8;
	}
	&:last-child {
		margin: 0;
	}
	.user-legend-icon {
		margin: 0 6px 0 0;
		font-size: 0;
		svg {
			fill: $primary;
			width: 11px;
			height: 13px;
		}
	}
	.user-content {
		margin: 0 12px;
		overflow: hidden;
		flex-grow: 1;
		flex-shrink: 1;
		.push-name {
			color: $primary;
			// color: $textGrey;
			margin: 0 0 8px;
			font-size: 12px;
			font-weight: 700;
			text-transform: uppercase;
		}
	}
	h4 {
		color: $textWhite;
		font: 700 15px $fontFamily;
		margin: 0 0 6px;
		display: flex;
		align-items: center;
	}
	h4 + p {
		color: $textGrey;
		font: 600 14px $fontFamily;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.user-actions {
		white-space: nowrap;
		button:not(:first-child) {
			margin: 0 0 0 12px;
		}
	}
	&.clickable {
		cursor: pointer;
	}
}

.users-list--item.animated {
	&.enter {
		opacity: 0;
		transform: translateY(10px);
	}
	&.enter-active {
		opacity: 1;
		transform: translateY(0);
		transition: opacity .2s ease, transform .2s ease;
	}
	&.exit {
		opacity: 1;
		transform: translateY(0);
	}
	&.exit-active {
		opacity: 0;
		transform: translateY(10px);
		transition: opacity .2s ease, transform .2s ease;
	}
}

.users-list--item.is-featured {
	border: 1px solid $primary !important;
	box-shadow: 0 2px 8px $primary30o;
}

.users-list--empty {
	p {
		font: 600 16px $fontFamily;
		color: $textGrey;
	}
}

.user-role {
	color: $textGrey;
	padding: 2px 4px;
	display: inline-block;
	vertical-align: top;
	font-size: 10px;
	font-weight: 700;
	background: $backgroundSecondary10;
	border-radius: $borderRadius;
	&:not(:first-child) {
		margin-left: 6px;
	}
	&.admin {
		color: $blue;
		background: $blue1o;
	}
	&.business {
		color: $secondary20;
		background: $secondary15o;
	}
	&.athlete {
		color: $primary;
		background: $primary15o;
	}
	&.big {
		font-size: 14px;
		backdrop-filter: blur(10px);
	}
}

.request-status {
	color: $textGrey;
	padding: 2px 4px;
	display: inline-block;
	vertical-align: middle;
	font-size: 10px;
	font-weight: 700;
	background: $backgroundSecondary2;
	&.request-s5 {
		color: $primary;
		background: $primary1o;
	}
}

.user-avatar {
	width: 50px;
	position: relative;
	img {
		width: 50px;
		height: 50px;
		border: 1px solid $white15o;
		display: block;
		object-fit: cover;
		border-radius: $borderRadius;
	}
	.counter {
		background: $danger;
		position: absolute;
		top: -8px;
		right: -8px;
		padding: 0px 4px;
		font-size: 12px;
		font-weight: 700;
		border: 4px solid $backgroundPrimary2;
		border-radius: 16px;
		min-width: 28px;
		text-align: center;
	}
}

.avatar-empty {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: $backgroundSecondary;
	border-radius: $borderRadius;
}

.app-over-item .app-over-item__inner.create {
	padding: 24px 28px;
	.actions button {
		width: 100%;
		margin: 20px 0 0;
		display: block;
	}
	.over-item-title {
		margin: 0 0 20px;
		h4 {
			color: $textWhite;
			font-weight: 600;
		}
		button {
			border: 1px solid $white15o;
		}
		svg {
			fill: $iconWhite;
		}
	}
	.app-input,
	.app--r-select {
		margin: 0 0 20px;
	}
}
.app-over-item .app-over-item__inner.create-promo-code {
	width: 700px;
}

.app-over-item .app-over-item__inner.request {
	width: 340px;
	padding: 24px 28px;
	.app-legend h4 {
		background: $backgroundSecondary;
	}
	.actions button {
		width: 100%;
		margin: 20px 0 0;
		display: block;
	}
	.over-item-title {
		margin: 0 0 20px;
		h4 {
			color: $textWhite;
			font-weight: 600;
		}
		button {
			border: 1px solid $white15o;
		}
		svg {
			fill: $iconWhite;
		}
	}
	.app-input {
		margin: 0 0 20px;
	}
	.app-option-view {
		padding: 12px 14px;
	}
}

.user-profile {
	.user-overlay {
		@include absolute-fill(100);
		@include gradient(
			to bottom,
			$transparent 10%,
			$backgroundPrimary49o 70%,
			$backgroundPrimary4
		);
		&.empty {
			@include gradient(
				to bottom,
				$backgroundPrimary40d3o,
				$backgroundPrimary4
			);
		}
	}
	.user-icon {
		position: absolute;
		z-index: 1000;
		top: 50%;
		left: 50%;
		padding: 20px;
		transform: translate(-50%, -90%);
		background: $primary05o;
		border-radius: 50%;
		svg {
			fill: $primary;
			width: 42px;
			height: 42px;
			display: block;
		}
	}
	.user-background {
		height: 410px;
		background: no-repeat top center/cover;
		position: relative;
	}
	.user-info {
		z-index: 110;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 28px;
		text-align: center;
		h4 {
			margin: 6px 0 0;
			font-size: 20px;
			font-weight: 700;
		}
		h6 {
			color: $textGrey;
			margin: 4px 0 0;
			font-size: 14px;
			font-weight: 600;
		}
	}
}

.page-aside .user-content {
	padding: 0 20px 20px;
	position: relative;
	z-index: 200;
	.app-legend h4 {
		background: $backgroundPrimary4;
	}
	.app-legend:not(:last-child) {
		margin: 0 0 30px;
	}
	.user-actions {
		.app-button {
			width: 100%;
			&:not(:last-child) {
				margin: 0 0 16px;
			}
		}
	}
}

.request-list--item {
	padding: 12px;
	margin: 0 0 20px;
	border: 1px solid $backgroundPrimary5;
	display: flex;
	align-items: center;
	flex-direction: row;
	background: $backgroundPrimary2;
	cursor: pointer;
	transition: background .2s ease;
	border-radius: $borderRadius;
	&:hover {
		background: $backgroundPrimary4;
		border-color: $backgroundPrimary8;
	}
	&:last-child {
		margin: 0;
	}
	svg {
		fill: $primary;
		width: 20px;
	}
	h4 {
		color: $textWhite;
		font: 700 15px $fontFamily;
		margin: 0 0 6px;
	}
	h4 + p {
		color: $textGrey;
		font: 600 14px $fontFamily;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		a {
			color: $primary;
			text-decoration: underline;
			font-weight: 700;
		}
		b {
			color: $textWhite;
			font-weight: 700;
		}
	}
	h4 + p + p {
		color: $primary;
		font: 800 12px $fontFamily;
		margin: 6px 0 0;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.request-item--content {
	margin: 0 14px;
	flex-grow: 1;
	flex-shrink: 1;
	.paypal-id {
		color: $textWhite6d;
	}
}

.request-item--amount {
	color: $primary;
	padding: 3px 5px;
	font-size: 14px;
	font-weight: 600;
	background: $primary1o;
	&:not(:last-child) {
		margin-right: 12px;
	}
}

.app-option-view.service {
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.app-option-view--label {
		color: $textGrey;
		font-size: 10px;
		font-weight: 700;
		text-transform: uppercase;
	}
	.app-option-view--value {
		display: flex;
		align-items: center;
		p {
			font-size: 20px;
			font-weight: 600;
		}
		p:not(:first-child):last-child {
			color: $textGrey;
			margin: 0 0 0 4px;
			font-size: 12px;
			font-weight: 600;
		}
	}
}

.stats-page {
	display: flex;
	flex-wrap: wrap;
	& > div {
		width: calc(50% - 20px);
		margin: 0 40px 40px 0;
		border: 1px solid $backgroundSecondary4;
		padding: 20px;
		background: $backgroundSecondary4d;
		border-radius: $borderRadius;
		.app-legend-head h4 {
			@include gradient(
				to bottom,
				$backgroundSecondary4d 50%,
				$backgroundPrimary3 50%
			);
		}
		&:nth-child(2n) {
			margin-right: 0;
		}
		&:last-child {
			margin: 0;
		}
	}
}

.payouts--header {
	display: block;
	a {
		color: $primary;
		margin: 6px 0 0;
		display: inline-block;
		font-size: 16px;
		font-weight: 600;
		text-decoration: underline;
	}
}

.page-header-flex-wrap {
	display: flex;
	justify-content: space-between;
}

.payouts--page {
	padding: 0;
	.page-header {
		padding: 38px;
		display: block;
		padding-bottom: 0;
		a {
			color: $primary;
			margin: 6px 0 0;
			display: inline-block;
			font-size: 16px;
			font-weight: 600;
			text-decoration: underline;
		}
	}
	.page-middle {
		padding: 0 38px;
	}
	.app--check {
		width: 22px;
		margin: 0;
		display: block;
	}
	table {
		width: 100%;
		border-spacing: 0;
		border-collapse: collapse;
		tr {
			border-top: 1px solid $backgroundPrimary5;
		}
		tr:last-child {
			border-bottom: 1px solid $backgroundPrimary5;
		}
		tr:nth-child(odd) {
			background: $backgroundPrimary2;
		}
		thead td {
			color: $textGrey;
			font-size: 10px;
			font-weight: 700;
			text-transform: uppercase;
		}
		tfoot td:last-child {
			padding-top: 12px;
			padding-bottom: 12px;
			padding-left: 4px;
		}
		td {
			color: $textWhite6d;
			padding: 12px;
			padding-left: 4px;
			padding-right: 4px;
			font-size: 13px;
			font-weight: 500;
			vertical-align: middle;
			&.empty {
				color: $textGrey12d;
			}
			&:last-child {
				padding: 0;
				padding-right: 0 !important;
			}
		}
		td:first-child {
			padding-left: 38px;
			padding-right: 0;
		}
		td:last-child {
			padding-right: 38px;
		}
	}
	.amount {
		input {
			width: 90px;
			color: $textWhite6d;
			font-size: 13px;
			font-weight: 500;
			padding: 12px;
			padding-left: 4px;
			padding-right: 38px;
		}
	}
	.amount-blank {
		width: 90px;
	}
}

.eased {
	opacity: 0;
	transition: opacity .2s ease;
	&.shown {
		opacity: 1;
	}
}

.profile-edit {
	padding: 0;
	.edit-head--inner {
		padding: 38px;
		position: relative;
	}
	.edit-head--bg-over,
	.edit-head--bg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 10;
		background: no-repeat center center/cover;
		border-radius: 9px 9px 0 0;
	}
	.edit-head--bg-over {
		z-index: 20;
		border-radius: 8px 8px 0 0;
		backdrop-filter: blur(12px);
		@include gradient(to bottom, $backgroundPrimary7o, $backgroundPrimary2);
	}
	.edit-head--title {
		display: flex;
		min-height: 56px;
		align-items: center;
		position: relative;
		z-index: 30;
		h1 {
			font-size: 28px;
			min-height: 36px;
			line-height: 36px;
			font-weight: 700;
		}
		p {
			color: $primary;
			font-size: 16px;
			font-weight: 600;
		}
		.app-square-icon {
			width: 35px;
			height: 35px;
			min-width: auto;
			min-height: auto;
			position: absolute;
			top: -28px;
			right: -28px;
			border: none;
			border-radius: 8px;
			svg { fill: $textGrey; }
		}
		.user-avatar {
			width: 56px;
			height: 56px;
			margin: 0 20px 0 0;
		}
	}
	.app--tabs-head {
		border-top: 1px solid $backgroundPrimary10;
	}
	.edit--modal-wrap {
		padding: 38px;
	}
	.app-legend:not(:first-child) {
		margin: 28px 0 0;
	}
	.app-legend--item .app-button {
		width: 100%;
	}
	.app-legend + .app-button {
		width: 100%;
		margin: 28px 0 0;
		&:not(:last-child) {
			margin-bottom: 18px;
		}
	}
	p.message {
		color: $textGrey;
		font-size: 15px;
		text-align: center;
		font-weight: 600;
		transition: color .2s ease;
		&.active {
			color: $danger;
		}
	}
	.field--wrapper {
		padding: 28px;
		& > div:not(:last-child) {
			margin: 0 0 28px;
		}
	}
}

.edit--modal {
	width: $editModalWidth;
	padding: 0 !important;
	background: $backgroundPrimary !important;
	border-radius: 12px;
	.app--map-wrap {
		position: fixed;
		width: $editModalWidth;
		left: calc(50% - (#{$editModalWidth}/2));
	}
}

.manage-overlay {
	position: fixed;
	transform: translate3d(0, 0, 0);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	background: $black8o;
	backdrop-filter: blur(10px);
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	pointer-events: none;
	transition: opacity .2s ease;
	.content {
		display: flex;
		padding: 40px;
		background: $black4o;
		align-items: center;
		border-radius: 12px;
		flex-direction: column;
		h2 {
			color: $textWhite6d;
			margin: 18px 0 0;
			font-size: 22px;
			font-weight: 800;
		}
		h6 {
			color: $textGrey;
			margin: 6px 0 0;
			font-size: 14px;
			font-weight: 800;
		}
		.loader i {
			width: 32px;
			height: 32px;
			border-width: 2px;
		}
	}
}

.manage-content.manage_overlay {
	overflow: hidden;
	.manage-overlay {
		opacity: 1;
		pointer-events: auto;
	}
}

.promo-tags {
	margin: 10px 0 0;
	display: flex;
	flex-direction: row;
}

.promo-tag {
	padding: 2px 6px;
	border-radius: 6px;
	font-size: 13px;
	font-weight: 700;
	color: $grey46;
	background: $grey4605o;
	&:not(:last-child) {
		margin-right: 6px;
	}
	&.used {
		color: $primary;
		background: $primary15o;
	}
}

.archive-switch {
	display: flex;
	align-items: center;
	p {
		color: $white40d;
		margin: 0 0 0 10px;
		font-size: 15px;
		font-weight: 600;
		line-height: 20px;
	}
}

.modal--row {
	display: flex;
	flex-direction: row;
	& > div {
		width: 50%;
		flex-grow: 1;
		flex-shrink: 1;
		&:nth-child(2n) {
			margin-left: 28px !important;
		}
	}
}

.manage-aside--tenants {
}

.manage-content--tenants .manage-content {
	.page-header {
		margin-bottom: 0;
	}
}

.manage-content--tenants {
	table {
		width: 100%;
    border-spacing: 0 20px;
		border-collapse: separate;
		tr {
			margin-bottom: 20px;
		}
		.logo-wrap {
			display: inline-block;
			padding: 4px 12px;
			border-radius: 5px;
			vertical-align: top;
			background-color: $backgroundSecondary4;
			img {
				width: 140px;
			}
		}
		tbody tr {
			cursor: pointer;
			transition: background-color .2s ease;
			border-radius: 5px;
			background-color: $backgroundSecondary;
			&:hover {
				background-color: $backgroundSecondary2;
			}
		}
		thead td {
			color: $textGrey;
			padding: 0;
			font-size: 14px;
			font-weight: 400;
			background-color: transparent;
		}
		td {
			color: $textWhite6d;
			padding: 10px;
			font-size: 18px;
			text-align: center;
			font-weight: 600;
			vertical-align: middle;
			&.empty {
				color: $textGrey12d;
			}
			&:last-child {
				padding: 0;
				padding-right: 0 !important;
			}
		}
		td:first-child {
			border-radius: 5px 0 0 5px;
		}
		td:last-child {
			border-radius: 0 5px 5px 0;
		}
		.logo,
		.name {
			width: auto;
			text-align: left;
		}
		.logo {
			width: 20px + 140px + 12px * 2;
		}
		.name {
		}
	}
}

.user-option {
	padding: 8px 14px !important;
	display: flex !important;
	transition: background-color .2s ease;
	align-items: center;
	justify-content: center;
	.user-avatar,
	.user-avatar img {
		width: 32px;
		height: 32px;
	}
	.user-text {
		margin: 0 0 0 12px;
		min-width: 0;
		flex-grow: 1;
		flex-shrink: 1;
	}
	.user-name {
		font: 500 15px/1.2 $fontFamily;
		color: $textWhite10d;
	}
	.user-email {
		font: 600 12px/1.2 $fontFamily;
		color: $textGrey;
		margin: 1px 0 0;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.container-error {
	display: flex;
	align-items: center;
	justify-content: center;
}

.container-error__message {
	width: 500px;
	border: 1px solid $backgroundPrimary16;
	padding: 20px;
	text-align: center;
	box-shadow: 0 4px 24px $backgroundPrimary1d1o;
	background: $backgroundPrimary8;
	border-radius: $borderRadius;
	h1 {
		font: 600 20px $fontFamily;
		color: $textWhite10d;
	}
	h1 + p {
		font: 500 14px $fontFamily;
		color: $textGrey;
		margin: 12px 0 0;
	}
	p + .app-button {
		margin: 32px 0 0;
	}
}

.users-list--ticket {
	transition: background-color .2s ease, border-color .2s ease;
	&.has-new-messages {
		box-shadow: 0 4px 10px $primary30o;
		border-color: $primary;
		&:hover {
			border-color: $primary;
		}
	}
}
