.app--roster {
	border: 1px solid $backgroundPrimary10;
	transition: background-color .2s ease, border-color .2s ease;
	background: $backgroundPrimary4;
	box-shadow: 0 2px 4px $backgroundPrimary1d4o;
	border-radius: $borderRadius;
	&:not(:last-child) {
		margin: 0 0 20px;
	}
	&:not(.app--roster_selected) {
		cursor: pointer;
		&:hover {
			border-color: $backgroundPrimary12;
			background-color: $backgroundPrimary6;
		}
	}
}

.app--roster__inner {
	padding: 12px 18px;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: space-between;
	h4 {
		font: 500 15px/1.2 $fontFamily;
		color: $textWhite10d;
		overflow: hidden;
		white-space: wrap;
		text-overflow: ellipsis;
	}
	.roster-content {
		flex-grow: 1;
		flex-shrink: 1;
		min-width: 0;
		&:not(:first-child) {
			margin: 0 18px;
		}
		.unread-count {
			font: 700 12px $fontFamily;
			color: $primary;
			margin: 0 0 6px;
			padding: 2px 4px;
			display: inline-flex;
			background: $primary15o;
			border-radius: 3px;
		}
	}
	.roster-arrow {
		font-size: 0;
		position: absolute;
		bottom: 10px;
		right: 10px;
		& > svg {
			fill: $darkGrey;
		}
	}
	.app--flag {
		width: 20px;
		flex-shrink: 0;
	}
}

.app--roster_selected {
	border-color: $primary;
	background-color: $primary05o;
}
