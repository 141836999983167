.app--stats-head {
	margin: 0 0 28px;
	text-align: center;
	img,
	.img-placeholder {
		width: 64px;
		min-height: 64px;
		margin: 0 0 8px;
		display: inline-block;
		background: $backgroundPrimary10;
		border-radius: $borderRadius;
	}
	img {
		object-fit: cover;
	}
	.img-placeholder {
		font-size: 0;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		svg {
			fill: $backgroundPrimary16;
		}
	}
	h2 {
		color: $textWhite6d;
		margin: 0 0 6px;
		font-size: 22px;
		font-weight: 600;
	}
	h2 + p {
		color: $textGrey;
		font-size: 15px;
		font-weight: 700;
	}
}
