.app--map-location {
	margin: 0 0 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	&:last-child {
		margin: 0;
	}
}

.app--map-location-content {
	margin: 0 14px;
	min-width: 0;
	flex-grow: 1;
	flex-shrink: 1;
	.name {
		color: $textWhite6d;
		font-size: 15px;
		min-width: 0;
		font-weight: 600;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.radius {
		color: $textGrey;
		margin: 4px 0 0;
		font-size: 12px;
		font-weight: 700;
		text-transform: uppercase;
	}
}
