.app--button-input {
	position: relative;
	overflow: hidden;
	&:after,
	span {
		pointer-events: none;
	}
	input {
		position: absolute;
		top: 0;
		left: -50%;
		right: 0;
		width: 150%;
		bottom: 0;
		cursor: pointer;
		opacity: 0;
	}
	&:not(:disabled):hover {
		background: $grey8;
	}
	&.app-button--primary {
		background: $primary;
		&:not(:disabled):hover {
			background: $primary8;
		}
	}
	&.app-button--secondary {
		background: $secondary;
		&:not(:disabled):hover {
			background: $secondary8;
		}
	}
}
