/* Chat
 */
.manage-content.chat {
	display: flex;
	flex-direction: column;
	.page-body {
		display: flex;
		flex-grow: 1;
		// overflow-y: hidden;
		flex-direction: column;

		overflow-y: auto;
		overflow-x: hidden;

		border: 1px solid $backgroundPrimary10;
		background: $backgroundPrimary4;
		box-shadow: 0 4px 24px $backgroundPrimary2d;
		border-radius: 6px;

		position: relative;
	}
}

.app--chat-head {
	position: sticky;
	right: 0;
	left: 0;
	top: 0;
	background: $backgroundPrimary27o;
	backdrop-filter: blur(12px);
	z-index: 30;
	display: flex;
	align-items: center;
	border-bottom: 1px solid $backgroundPrimary10;
	border-radius: 5px 5px 0 0;
	svg {
		fill: $primary;
		flex-shrink: 0;
	}
	p {
		font: 500 16px $fontFamily;
		color: $textWhite10d;
		margin: 0 0 0 12px;
		flex-grow: 1;
		flex-shrink: 1;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.app-button {
		padding: 4px 8px;
		margin-right: 8px;
	}
}

.app--chat-head__content {
	min-width: 0;
	flex-grow: 1;
	flex-shrink: 1;
	padding: 12px 17px;
	display: flex;
	align-items: center;
}


/* Chat Messages
 */
.app--chat-messages {
	flex-grow: 1;
	display: flex;
	padding: 30px;
	padding-bottom: 20px;
	align-items: stretch;
	flex-direction: column;
	justify-content: flex-end;
}

.app--chat-messages_empty {
	display: flex;
	align-items: center;
	justify-content: center;
	p {
		font-size: 16px;
		font-weight: 500;
	}
}


/* Chat Message
 */
.app--chat-message {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	&:not(:last-child) {
		margin: 0 0 20px;
	}
	.app--video-wrap {
		video,
		& {
			width: 100%;
			height: 100%;
			position: absolute;
			object-fit: contain;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
		.overlay {
			background: rgba(#000, .3);
		}
	}
}

.app--chat-message__inner {
	padding: 10px;
	position: relative;
	max-width: 80%;
	background: $backgroundPrimary1d;
	box-shadow: 0 2px 8px $backgroundPrimary;
	border-radius: 10px 10px 0 10px;
	display: flex;
	align-items: flex-end;
	flex-direction: row;
	.app--video-wrap video,
	.app--video-wrap .overlay,
	.app--chat-message__image {
		border-radius: 10px 10px 0 10px;
	}
}

.app--chat-message_outer {
	justify-content: flex-start;
	.app--chat-message__inner {
		border: 1px solid $backgroundPrimary16;
		background: $backgroundPrimary10;
		box-shadow: 0 2px 8px $backgroundPrimary8;
		border-radius: 10px 10px 10px 0;
		.app--video-wrap video,
		.app--video-wrap .overlay,
		.app--chat-message__image {
			border-radius: 10px 10px 10px 0;
		}
	}
}

.app--chat-message_clickable {
	.app--chat-message__inner {
		cursor: pointer;
		transition: background-color .2s ease, border-color .2s ease;
		&:hover {
			background: $backgroundPrimary6d;
		}
	}
	&.app--chat-message_outer {
		.app--chat-message__inner:hover {
			background: $backgroundPrimary12;
			border-color: $backgroundPrimary20;
		}
	}
}





.app--chat-message__text {
	color: $textWhite10d;
	font-size: 16px;
	font-weight: 400;
	flex-grow: 1;
	flex-shrink: 1;
}

.app--chat-message__info {
	margin: 0 0 0 10px;
	z-index: 20;
	display: flex;
	align-self: flex-end;
	align-items: center;
	flex-direction: row;
}

.app--chat-message__date {
	color: $textGrey12d;
	z-index: 20;
	font-size: 13px;
	text-align: right;
	font-weight: 500;
	white-space: nowrap;
	// float: right;
	// shape-outside: inset(calc(100% - 16px) 0 0);
}

.app--chat-message__read-status {
	margin: 2.5px 0 0 4px;
	font-size: 0;
	svg {
		fill: $textGrey12d;
		path {
			transition: opacity .2s ease;
		}
	}
}

.app--chat-message__asset {
	display: flex;
	min-width: 0;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	.content {
		margin: 0 0 0 12px;
		min-width: 0;
		h4 {
			font: 600 16px/1.2 $fontFamily;
			color: $textWhite10d;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		p {
			font: 500 14px/1.2 $fontFamily;
			color: $textGrey12d;
			margin: 4px 0 0;
		}
	}
}

.app--chat-message_media-asset {
	.app--chat-message__inner {
		padding: 0;
		position: relative;
		max-width: 220px;
		min-width: 35%;
		min-height: 40px;
		box-shadow: 0 2px 8px $backgroundPrimary;
	}
	.app--chat-message__info {
		position: absolute;
		padding: 1px 7px;
		bottom: 10px;
		right: 10px;
		backdrop-filter: blur(10px);
		border-radius: 14px;
		background: rgba(#000, .3);
	}
	.app--chat-message__date {
		color: $textWhite;
	}
	.app--chat-message__read-status {
		svg {
			fill: $textWhite;
		}
	}
}

.app--chat-message__image {
	width: 100%;
	position: absolute;
	object-fit: contain;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	& + i {
		width: 100%;
		display: block;
	}
}




/* Chat Form
 */
.app--chat-form {
	padding: 30px;
	padding-top: 10px;
	z-index: 30;
	position: sticky;
	bottom: 0;
	right: 0;
	left: 0;
	@include gradient(to bottom, $backgroundPrimary4_o, $backgroundPrimary4);
}

.app--chat-form__inner {
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;

	border: 1px solid $backgroundPrimary12;
	padding: 2px;
	background: $backgroundPrimary5;
	box-shadow: 0 4px 12px $backgroundPrimary;
	border-radius: 3px;
	& > button,
	& > .app-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 48px;
		height: 48px;
		padding: 0;
		box-shadow: none;
		background: transparent;
		font-size: 0;
		flex-shrink: 0;
		svg {
			opacity: .7;
			transition: opacity .2s ease;
			@include backface(20px);
		}
		&:not(:disabled):hover {
			background: transparent;
			svg {
				opacity: 1;
			}
		}
	}
	& > button {
		flex-shrink: 0;
		border-radius: 2px;
	}
	&.close-message {
		align-items: center;
		div {
			margin: 10px;
			text-align: center;
		}
		h4 {
			font: 600 14px/1.2 $fontFamily;
			color: $textGrey;
			text-align: center;
		}
		h4 + p {
			margin: 4px 0 0;
			b {
				font-weight: 600;
			}
		}
	}
}

.app--chat-form__input {
	font: 400 15px $fontFamily;
	width: 100%;
	color: $textWhite10d;
	padding: 14.5px 0;
}

.app--chat-form__asset {
	min-width: 0;
	flex-grow: 1;
	flex-shrink: 1;
	.app-square-icon {
		flex-shrink: 0;
	}
	.app--chat-message__asset {
		border: 1px solid $backgroundPrimary10;
		padding: 6px 8px;
		background: $backgroundPrimary;
		border-radius: 3px;
		.content {
			margin: 0 12px;
			flex-grow: 1;
			flex-shrink: 1;
		}
	}
}

.app--manage-search {
	position: relative;
}

.app--manage-search__inner {
	display: flex;
	flex-direction: row;
	.search > .loader,
	.search > svg {
		top: calc(50% - 16px/2);
		fill: $textPlaceholderColor;
		color: $textPlaceholderColor;
		left: 15px;
		width: 16px;
		height: 16px;
	}
	.app-input {
	}
	.app-input--element {
		font: 500 15px/1.2 $fontFamily;
		width: 320px;
		border: 1.5px solid $backgroundSecondary10;
		padding: 15px 20px;
		padding-left: 15px + 16px + 10px;
		box-shadow: 0 2px 14px $backgroundPrimary8d2o;
		transition: border-color .2s ease, box-shadow .2s ease;
		border-radius: 6px;
		@include placeholder($textPlaceholderColor, $textPlaceholderColorFocus);
		&:not(:disabled):focus {
			box-shadow: 0 2px 18px $primary15o;
			border-color: $primary;
		}
	}
}

.app--manage-search__results {
	border: 1.5px solid $backgroundSecondary10;
	border-radius: 8px;
	box-shadow: 0 6px 18px $backgroundPrimary8d2o;
	background: $backgroundPrimary;
	position: absolute;
	min-height: 64px;
	max-height: 340px;
	overflow-y: auto;
	padding: 6px 0;
	overflow-x: hidden;
	z-index: 100;
	right: 0;
	left: 0;
	top: 61px;

	.results-separator {
		width: calc(100% - 12px * 2);
		height: 1.5px;
		margin: 6px 12px;
		background: $backgroundSecondary2;
		border-radius: 1px;
	}

	&.empty-results {
		min-height: 120px;
	}

	.empty-result {
		font: 500 16px/105px $fontFamily;
		color: $textGrey;
		text-align: center;
	}

	.result-option {
		cursor: pointer;
		display: flex;
		padding: 6px 12px;
		transition: background-color .15s ease;
		align-items: center;
		flex-direction: row;
		&:hover {
			background: $backgroundPrimary4;
		}
		.app-square-icon {
			width: 32px;
			height: 32px;
			min-width: 32px;
			min-height: 32px;
		}
		.user-avatar {
			width: 32px;
			height: 32px;
		}
		.option-content {
			min-width: 0;
			flex-grow: 1;
			flex-shrink: 1;
			margin-left: 12px;
			h4, p {
				overflow: hidden;
				text-overflow: ellipsis;
			}
			h4 {
				font: 500 15px $fontFamily;
				color: $textWhite10d;
				b {
					font-weight: 700;
					text-decoration: underline;
				}
			}
			p {
				font: 500 13px $fontFamily;
				color: $textGrey;
				margin: 2px 0 0;
			}
		}
	}

	&.enter {
		opacity: 0;
	}
	&.enter-active {
		opacity: 1;
		transition: opacity .2s ease, transform .2s ease;
	}
	&.exit {
		opacity: 1;
	}
	&.exit-active {
		opacity: 0;
		transition: opacity .2s ease, transform .2s ease;
	}
	.loader-absolute {
		z-index: 100;
		background: $backgroundPrimary48o;
		&.loader-loading {
			pointer-events: auto;
		}
	}

}
