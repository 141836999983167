.pages {
	display: flex;
	margin: 28px 0 0;
	li {
		cursor: pointer;
		border: 1px solid $white15o;
		margin: 0 12px 0 0;
		background: $backgroundSecondary4d;
		transition: background-color .2s ease;
		border-radius: $borderRadius;
		&:not(.disabled):hover {
			background: $backgroundSecondary4;
		}
		a {
			display: block;
			padding: 10px;
			outline: none;
			font-weight: 600;
		}
		&:last-child {
			margin: 0;
		}
		&.disabled {
			cursor: default;
			opacity: .2;
		}
		&.selected {
			color: $primary;
			border-color: $primary;
			&:hover {
				background: $primary015o;
			}
		}
	}
}
