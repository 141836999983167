.app--switch {
	padding: 3px;
	cursor: pointer;
	position: relative;
	&:not(.selected):not(:disabled):hover {
		.app--switch-track {
			background: $backgroundPrimary16;
		}
		.app--switch-knob {
			background: $iconWhite;
		}
	}
	&.selected {
		.app--switch-track {
			background: $primary;
		}
		.app--switch-knob {
			background: $iconWhite;
			transform: translateX(100%);
		}
	}
}

.app--switch-knob {
	width: 20px;
	height: 20px;
	background: $textGrey;
	position: relative;
	z-index: 20;
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0;
	transition: background-color .2s ease, transform .2s ease;
	box-shadow: 0 2px 4px $backgroundPrimary6d4o;
}

.app--switch-track {
	width: 34px;
	height: 14px;
	position: relative;
	z-index: 10;
	border-radius: 34px;
	background: $backgroundPrimary12;
	box-shadow: 0 2px 4px $backgroundPrimary1d4o;
	transition: background-color .2s ease;
}

.app-switch {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	.app-switch--text {
		margin-right: 15px;
	}
	p.label {
		font-size: 16px;
		font-weight: 700;
		text-transform: uppercase;
	}
	p.note {
		color: $textGrey12d;
		font-size: 14px;
		margin-top: 5px;
		font-weight: 500;
	}
}
