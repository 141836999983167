.endorsement--athlete {
	padding: 18px;
	display: flex;
	align-items: center;
	img {
		width: 50px;
		height: 50px;
		object-fit: cover;
		border-radius: $borderRadius;
	}
	.content {
		margin: 0 16px;
		flex-grow: 1;
		flex-shrink: 1;
		h6 {
			color: $textWhite6d;
			font-size: 15px;
			font-weight: 600;
		}
		h6 + p {
			color: $textGrey;
			margin: 6px 0 0;
			font-size: 14px;
			font-weight: 700;
		}
	}
	.status-icon {
		width: 28px;
		height: 28px;
		margin: 0 0 0 18px;
		display: flex;
		background: $backgroundPrimary12;
		border-radius: $borderRadius;
		svg {
			fill: $textGrey;
			width: 10px;
			height: auto;
			margin: auto;
		}
		&.status--accepted {
			background: $primary;
			svg {
				fill: $iconWhite;
			}
		}
		&.status--canceled {
			background: $danger;
			svg {
				fill: $iconWhite;
			}
		}
	}
}
