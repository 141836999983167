.app-square-icon {
	border: 1px solid $white15o;
	min-width: 50px;
	min-height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: border-color .2s ease, background-color .2s ease;
	background: $backgroundPrimary5;
	box-shadow: 0 4px 6px $backgroundPrimary1d2o;
	border-radius: $borderRadius;
	svg {
		fill: $primary;
		transition: fill .2s ease;
	}
	&.clickable {
		cursor: pointer;
		&:not(:disabled):hover {
			background: $backgroundPrimary8;
			border-color: $white15o;
			svg {
				fill: $primary;
			}
		}
	}
}

.app-square-icon_plain {
	border: 0;
	box-shadow: none;
	svg {
		fill: #fff !important;
	}
	&[style] {
		min-width: auto;
		min-height: auto;
	}
}

.app-square-icon_loading {
	pointer-events: none;
}

.app-square-icon_disabled {
	cursor: not-allowed !important;
	opacity: .4;
}
