.app--ticket {
	cursor: pointer;
	border: 1px solid $backgroundPrimary10;
	transition: background-color .2s ease, border-color .2s ease;
	background: $backgroundPrimary4;
	box-shadow: 0 2px 4px $backgroundPrimary1d4o;
	border-radius: $borderRadius;
	&:not(:last-child) {
		margin: 0 0 20px;
	}
	&:hover {
		border-color: $backgroundPrimary12;
		background-color: $backgroundPrimary6;
	}
	&.canceled .request-icon {
		background: $danger;
		svg {
			fill: $iconWhite;
		}
	}
	&.finished .request-icon {
		background: $primary;
		svg {
			fill: $iconWhite;
		}
	}
}

.app--ticket_new {

}

.app--ticket_open {

}

.app--ticket_has-unread {
	box-shadow: 0 4px 10px $primary30o;
	border-color: $primary;
	&:hover {
		border-color: $primary;
	}
}

.app--ticket_closed .request-icon {
	background: $danger;
	svg {
		fill: $iconWhite;
	}
}

.app--ticket_waiting_response {

}

.app--ticket__inner {
	padding: 12px 18px;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: space-between;
	h4 {
		font: 500 15px/1.2 $fontFamily;
		color: $textWhite10d;
		overflow: hidden;
		white-space: wrap;
		text-overflow: ellipsis;
	}
	.ticket-content {
		flex-grow: 1;
		flex-shrink: 1;
		min-width: 0;
		&:not(:first-child) {
			margin: 0 18px;
		}
		.unread-count {
			font: 700 12px $fontFamily;
			color: $primary;
			margin: 0 0 6px;
			padding: 2px 4px;
			display: inline-flex;
			background: $primary15o;
			border-radius: 3px;
		}
	}
	.ticket-icon {
		display: flex;
		width: 20px;
		height: 20px;
		background: $backgroundPrimary10;
		position: absolute;
		font-size: 0;
		top: 10px;
		right: 10px;
		border-radius: $borderRadius;
		svg {
			fill: $backgroundPrimary60;
			margin: auto;
		}
	}
	.ticket-arrow {
		font-size: 0;
		position: absolute;
		bottom: 10px;
		right: 10px;
		svg {
			fill: $darkGrey;
		}
	}
}
