.app--date-picker {
	display: flex;
	align-items: flex-start;
	justify-content: stretch;
	.app-square-icon {
		margin: 0 15px 0 0;
	}
	&.invalid {
		.form-control {
			border-color: $danger;
		}
	}
}

.app--date-picker-container {
	width: 100%;
	.form-control {
		width: 100%;
		font: 600 14px $fontFamily;
		color: $textWhite;
		border: 1px solid $white15o;
		padding: 15px 20px;
		padding-right: 36px;
		background: $backgroundPrimary4;
		box-shadow: 0 2px 4px $backgroundPrimary1d4o;
		border-radius: $borderRadius;
		@include placeholder($white8o, $white1o);
	}
}

.app--date-picker-element {
	position: relative;
	svg {
		position: absolute;
		top: calc(50% - 2px);
		right: 20px;
	}
}

.rdt {
	position: relative;
}

.rdtPicker {
	display: none;
	position: absolute;
	min-width: 250px;
	padding: 4px;
	margin: 8px 0;
	z-index: 99999 !important;
	background: $backgroundPrimary10;
	box-shadow: 0 2px 4px $backgroundPrimary1d4o;
	border: 1px solid $backgroundPrimary20;
	border-radius: $borderRadius;
}

.rdtOpen .rdtPicker {
	display: block;
}

.rdtStatic .rdtPicker {
	box-shadow: none;
	position: static;
}

.rdtPicker {
	.rdtTimeToggle {
		text-align: center;
		border-radius: $borderRadius;
	}
	table {
		width: 100%;
		margin: 0;
	}
	td,
	th {
		text-align: center;
		height: 28px;
	}
	td {
		cursor: pointer;
		&.rdtDay:hover,
		&.rdtHour:hover,
		&.rdtMinute:hover,
		&.rdtSecond:hover {
			background: $backgroundPrimary20;
			cursor: pointer;
		}
	}
	.rdtTimeToggle:hover {
		background: $backgroundPrimary20;
		cursor: pointer;
	}
	td {
		border-radius: $borderRadius;
		&.rdtOld,
		&.rdtNew {
			color: $textGrey;
		}
		&.rdtToday {
			position: relative;
			&:before {
				content: "";
				display: inline-block;
				border-left: 7px solid transparent;
				border-bottom: 7px solid $primary;
				position: absolute;
				bottom: 4px;
				right: 4px;
			}
		}
		&.rdtActive {
			background-color: $primary;
			color: $textWhite;
			&:hover {
				background-color: $primary;
				color: $textWhite;
			}
			&.rdtToday:before {
				border-bottom-color: $backgroundPrimary10;
			}
		}
		&.rdtDisabled {
			background: none;
			color: $textGrey;
			cursor: not-allowed;
			&:hover {
				background: none;
				color: $textGrey;
				cursor: not-allowed;
			}
		}
		span {
			&.rdtOld {
				color: $textGrey;
			}
			&.rdtDisabled {
				background: none;
				color: $textGrey;
				cursor: not-allowed;
				&:hover {
					background: none;
					color: $textGrey;
					cursor: not-allowed;
				}
			}
		}
	}
	th {
	}
	.dow {
		width: 14.2857%;
		border-bottom: none;
		cursor: default;
	}
	th {
		&.rdtSwitch {
			width: 100px;
			border-radius: $borderRadius;
		}
		&.rdtNext,
		&.rdtPrev {
			font-size: 21px;
			border-radius: $borderRadius;
			vertical-align: top;
		}
	}
}

.rdtPrev span,
.rdtNext span {
	display: block;
	line-height: 22px;
	-webkit-touch-callout: none;
	user-select: none;
}

.rdtPicker {
	th.rdtDisabled {
		background: none;
		color: $textGrey;
		cursor: not-allowed;
		&:hover {
			background: none;
			color: $textGrey;
			cursor: not-allowed;
		}
	}
	thead tr:first-of-type th {
		cursor: pointer;
		&:hover {
			background: $backgroundPrimary20;
		}
	}
	button {
		border: none;
		background: none;
		cursor: pointer;
		&:hover {
			background-color: $backgroundPrimary20;
		}
	}
	thead button {
		width: 100%;
		height: 100%;
	}
}

td {
	&.rdtMonth,
	&.rdtYear {
		height: 50px;
		width: 25%;
		cursor: pointer;
	}
	&.rdtMonth:hover,
	&.rdtYear:hover {
		background: $backgroundPrimary20;
	}
}

.rdtCounters {
	display: inline-block;
	& > div {
		float: left;
	}
}

.rdtCounter {
	height: 100px;
	width: 40px;
}

.rdtCounterSeparator {
	line-height: 100px;
}

.rdtCounter {
	.rdtBtn {
		height: 40%;
		line-height: 40px;
		cursor: pointer;
		display: block;
		-webkit-touch-callout: none;
		user-select: none;
		&:hover {
			background: $backgroundPrimary20;
		}
	}
	.rdtCount {
		height: 20%;
		font-size: 1.2em;
	}
}

.rdtMilli {
	vertical-align: middle;
	padding-left: 8px;
	width: 48px;
	input {
		width: 100%;
		font-size: 1.2em;
		margin-top: 37px;
	}
}

.rdtTime td {
	cursor: default;
}

.rdtSwitch {
	cursor: pointer !important;
	&:hover {
		background: $backgroundPrimary20;
	}
}
