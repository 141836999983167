.app--tabs {
	background: $backgroundPrimary;
	&.single .app--tabs-tab {
		cursor: default;
	}
}

.app--tabs-head {
	display: flex;
	box-shadow: 0 2px 4px $backgroundPrimary1d4o;
	border-bottom: 1px solid $backgroundPrimary10;
}

.app--tabs-tab {
	width: 100%;
	cursor: pointer;
	padding: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	text-align: center;
	background: $backgroundPrimary45o;
	transition: background-color .2s ease;
	border-right: 1px solid $backgroundPrimary10;
	&.selected {
		background: $backgroundPrimary5;
	}
	&:last-child {
		border-right: 0;
	}
	&:not(.selected):hover {
		background: $backgroundPrimary48o;
	}
	p {
		color: $textGrey;
		font-size: 12.5px;
		transition: color .2s ease;
		line-height: 20px;
		font-weight: 700;
		text-transform: uppercase;
		& + span {
			font: 700 12px $fontFamily;
			color: $primary;
			margin: 0 0 0 10px;
			padding: 2px 6px;
			min-width: 20px;
			background: $primary15o;
			transition: opacity .2s ease;
			border-radius: 20px;
		}
	}
	&.selected {
		p {
			color: $textWhite;
		}
	}
}
