.app--grid {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	& > div {
		font-size: 0;
		p {
			color: $primary;
			font-size: 28px;
			font-weight: 700;
		}
		svg {
			fill: $primary;
			font-size: 0;
		}
	}
	&[class*='grid--'] {
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: flex-start;
	}
	&[class*='--n4'] .app--grid-item {
		width: calc(22%);
		margin: 0 4% 4% 0;
		&:nth-child(4n) {
			margin-right: 0;
		}
		&:nth-last-child(-n+4) {
			margin-bottom: 0;
		}
	}
	&[class*='--n5'] .app--grid-item {
		width: calc(16.8%);
		margin: 0 4% 4% 0;
		&:nth-child(5n) {
			margin-right: 0;
		}
		&:nth-last-child(-n+5) {
			margin-bottom: 0;
		}
	}
	@media screen and (max-width: 480px) {
		&[class*='--n5'] .app--grid-item {
			width: calc(22%);
			margin: 0 4% 4% 0;
			&:nth-child(5n) {
				margin: 0 4% 4% 0 !important;
			}
			&:nth-child(4n) {
				margin-right: 0 !important;
			}
			&:nth-last-child(-n+4) {
				margin-bottom: 0 !important;
			}
		}
	}
	@media screen and (max-width: 414px) {
		&[class*='--n5'] .app--grid-item {
			width: calc(30.6%);
			margin: 0 4% 4% 0;
			&:nth-child(4n),
			&:nth-child(5n) {
				margin: 0 4% 4% 0 !important;
			}
			&:nth-child(3n) {
				margin-right: 0 !important;
			}
			&:nth-last-child(-n+3) {
				margin-bottom: 0 !important;
			}
		}
	}
}

.grid-4s {
	display: flex;
	flex-flow: wrap row;
	align-items: flex-start;
	align-content: flex-start;
	& > div {
		width: calc(22.75%);
		margin: 0 3% 3% 0;
		&:nth-child(4n) {
			margin-right: 0;
		}
		&:nth-last-child(-n+4) {
			margin-bottom: 0;
		}
	}
}

.grid-3s {
	display: flex;
	flex-flow: wrap row;
	align-items: flex-start;
	align-content: flex-start;
	& > div {
		width: calc(31.33%);
		margin: 0 3% 3% 0;
		&:nth-child(3n) {
			margin-right: 0;
		}
		&:nth-last-child(-n+3) {
			margin-bottom: 0;
		}
	}
}

.grid-2s {
	display: flex;
	flex-flow: wrap row;
	align-items: flex-start;
	align-content: flex-start;
	& > div {
		width: calc(48.5%);
		margin: 0 3% 3% 0;
		&:nth-child(2n) {
			margin-right: 0;
		}
		&:nth-last-child(-n+2) {
			margin-bottom: 0;
		}
	}
}
