.app-gallery__modal {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	z-index: 1000;
	bottom: 0;
	right: 0;
	left: 0;
	top: 0;
	background: rgba(#000, .9);
	.app-image {
		width: 100%;
		max-height: 100%;
		object-fit: contain;
		// max-height: calc(100vh - (((32px * 2) + 40px)) * 2);
		aspect-ratio: initial !important;
	}
	&.enter {
		opacity: 0;
	}
	&.enter-active {
		opacity: 1;
		transition: opacity .2s ease;
	}
	&.exit {
		opacity: 1;
	}
	&.exit-active {
		opacity: 0;
		transition: opacity .2s ease;
	}
	.app--photo-wrap {
		height: 100%;
		display: flex;
		img {
			margin: auto;
			width: 100%;
			height: 100%;
			object-fit: contain;
			flex-grow: 1;
			flex-shrink: 1;
		}
	}
	.app--video-wrap {
		width: auto;
		height: 100%;
		display: flex;
		background: transparent;
		video {
			background: transparent;
		}
	}
}

.app-gallery__minimize-button {
	position: absolute;
	z-index: 1001;
	right: 32px;
	top: 32px;
}
