.loader {
	opacity: 0;
	pointer-events: none;
	transition: opacity .2s ease;
	i {
		width: 16px;
		height: 16px;
		display: block;
		border-radius: 50%;
		border: 1.3px solid $iconWhite;
		border-bottom-color: transparent;
		animation: spin .8s ease infinite;
	}
	&.loader-absolute {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		i {
			width: 32px;
			height: 32px;
			margin: auto;
			border-width: 2px;
		}
	}
}

.loader-loading {
	opacity: 1;
}

@mixin keyframes($name) {
	@-moz-keyframes #{$name} { @content; }
	@-webkit-keyframes #{$name} { @content; }
	@-ms-keyframes #{$name} { @content; }
	@keyframes #{$name} { @content; }
}

@include keyframes('spin') {
	to {
		transform: rotate(360deg);
	}
}
