.app--input-phone {
	display: flex;
	align-items: flex-start;
	.app-square-icon {
		margin-right: 15px;
		svg {
			fill: $primary;
		}
	}
	&.invalid {
		.app--input-phone-element {
			border-color: $danger;
		}
	}
}

.app--input-phone-container {
	flex-grow: 1;
}

.app--input-phone-element {
	width: 100%;
	font: 400 14px $fontFamily;
	color: $textWhite;
	border: 1px solid $white15o;
	padding: 15px 20px;
	transition: border-color .2s ease;
}

.app-input--message {
	color: $danger;
	font: 500 14px/20px $fontFamily;
	margin: 6px 0 0;
	text-align: right;
}
