.crop--modal .app-modal--wrap {
	transition: none;
	.app-modal--content {
		width: 350px;
		@media screen and (max-width: 414px) {
			width: 100%;
		}
	}
}

.crop-modal {
	.app-button:not(:last-child) {
		margin: 0 0 12px;
	}
	.crop-wrap {
		position: relative;
		background: $backgroundTertiary8;
		&:not(:last-child) {
			margin: 0 0 22px;
		}
		i.ar {
			display: block;
			padding: 100% 0 0;
			pointer-events: none;
		}
	}
	.crop-message {
		color: $textGrey;
		margin: 0 0 12px;
		font-size: 15px;
		font-weight: 600;
		text-align: center;
	}
}
