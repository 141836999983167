.app--asset-view {
	position: relative;
	background: $backgroundSecondary10;
	border-radius: $borderRadius;
	.app-square-icon {
		position: absolute;
		z-index: 40;
		bottom: 10px;
		right: 10px;
		min-width: 32px;
		min-height: 32px;
	}
	&:before {
		content: '';
		display: block;
		padding: 0 0 100%;
	}
	& > div {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.app--video-wrap {
		width: 100%;
		height: 100%;
		button svg {
			width: 26px;
			height: 26px;
		}
	}
	.app--photo-wrap img,
	.app--video-wrap video {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: $borderRadius;
	}
}
