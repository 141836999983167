.app-tooltip {
	cursor: pointer;
	position: relative;
	font-size: 0;
	svg {
		fill: $textGrey12d;
		width: 12px;
		height: 12px;
	}
	p {
		position: absolute;
		top: 0;
		left: 18px;
		width: 160px;
		border: 1px solid $backgroundPrimary16;
		padding: 4px 8px;
		font-size: 14px;
		font-weight: 400;
		background: $backgroundPrimary10;
		border-radius: 4px;
		opacity: 0;
		transition: opacity .2s ease, transform .2s ease;
		transform: translateX(6px);
		pointer-events: none;
	}
	&:hover {
		p {
			opacity: 1;
			transform: translateX(0);
		}
	}
}
