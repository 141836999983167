@keyframes fadeIn {
	from { opacity: .4; }
}

.app--skeleton {
	position: absolute;
	top: 22px;
	left: 22px;
	right: 22px;
	bottom: 0;
	overflow: hidden;
	.skeleton--item {
		border: 1px solid $backgroundPrimary10;
		opacity: .8;
		position: relative;
		background: $backgroundPrimary4;
		box-shadow: 0 2px 4px $backgroundPrimary1d4o;
		border-radius: $borderRadius;
		@include gradient(
			to bottom,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 5%), 0) 0%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 5%), 0.013) 8.1%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 5%), 0.049) 15.5%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 5%), 0.104) 22.5%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 5%), 0.175) 29%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 5%), 0.259) 35.3%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 5%), 0.352) 41.2%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 5%), 0.45) 47.1%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 5%), 0.55) 52.9%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 5%), 0.648) 58.8%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 5%), 0.741) 64.7%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 5%), 0.825) 71%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 5%), 0.896) 77.5%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 5%), 0.941) 84.5%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 5%), 0.967) 91.9%,
			hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 5%)) 100%
		);
		&:after {
			z-index: 10;
			content: '';
			display: block;
			padding: 130% 0 0;
			border-radius: $borderRadius;
		}
		.inner {
			position: absolute;
			padding: 22px 20px;
			bottom: 0;
			right: 0;
			left: 0;
		}
		.long--item {
			width: 70%;
			margin: auto;
			height: 8px;
			animation: fadeIn 1s infinite alternate ease;
			background: $backgroundPrimary12;
			border-radius: $borderRadius;
		}
		.short--items {
			margin: 18px auto 0;
			display: flex;
			justify-content: center;
			i {
				width: 16%;
				height: 8px;
				margin: 0 6px;
				display: block;
				animation: fadeIn 1s infinite alternate ease .2s;
				background: $backgroundPrimary8;
				border-radius: $borderRadius;
			}
		}
	}
	&.type--notifications .skeleton--item {
		margin: 0 0 20px;
		.inner {
			top: 0;
			padding: 16.5px 18.5px;
			position: static;
		}
		.long--item {
			width: 63%;
			margin: 0;
		}
		.short--items {
			margin: 16px 0 0;
			justify-content: flex-start;
			i {
				margin: 0;
			}
		}
		&:last-child {
			margin: 0;
		}
		&:after {
			display: none;
		}
	}
}
