.app-legend {
	&:before {
		content: '';
		display: block;
		height: 10px;
	}
	&.error {
		.app-legend--inner {
			border-color: $danger;
		}
		.app-legend-head h4 {
			color: $danger;
		}
	}
	&.has-padding .app-legend--item {
		padding: 20px;
	}
}

.app-legend--inner {
	border: 1px solid $white15o;
	background: $backgroundPrimary3;
	box-shadow: 0 2px 4px $backgroundPrimary1d4o;
	transition: border-color .2s ease;
	border-radius: $borderRadius;
}

.app-legend-head {
	display: flex;
	justify-content: center;
	&:before,
	&:after {
		content: '';
		margin: -1px 0 0;
		height: 1px;
		min-width: 20px;
		flex-shrink: 1;
		border-radius: 0 4px 0 0;
	}
	&:before {
		margin-right: -1px;
		@include gradient(
			to right,
			transparent,
			$backgroundPrimary3
		);
	}
	&:after {
		margin-left: -1px;
		@include gradient(
			to left,
			transparent,
			$backgroundPrimary3
		);
	}
	h4 {
		color: $textWhite10d;
		margin: -10px 0 0;
		padding: 0 12px;
		position: relative;
		font-size: 12px;
		transition: color .2s ease;
		text-align: center;
		font-weight: 700;
		line-height: 20px;
		text-transform: uppercase;
		span {
			position: relative;
			z-index: 11;
		}
		&:after {
			content: '';
			width: 100%;
			height: 1px;
			background: $backgroundPrimary3;
			position: absolute;
			top: 9px;
			left: 0;
			right: 0;
			z-index: 10;
		}
	}
}

.app-legend-body {
	margin: -10px 0 0;
	a {
		color: $primary;
		font-weight: 500;
		text-decoration: underline;
	}
}

.app-legend--item {
	border-top: 1px solid $white15o;
	&:first-child {
		border-top: 0;
	}
}
